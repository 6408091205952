import React, { useEffect, useState } from 'react';
import './appointments.css';
import { useDispatch } from 'react-redux';
import {
    getCustomerAppointments,
    getPreviousAppointments,
} from '../redux/appointments/appointmentAction';
import { useSelector } from 'react-redux';

import AppointmentCard from '../components/cards/appointmentCard/appointmentCard';

const PreviousAppointments = ({ activeIndex, returnSelectedAppointment }) => {
    const { isLoading, previousAppointments } = useSelector(
        (state) => state.appointments
    );
    const dispatch = useDispatch();

    const handleCardClick = (index, appointment) => {
        returnSelectedAppointment(index, appointment);
    };

    useEffect(() => {
        dispatch(getPreviousAppointments());
    }, []);

    return (
        <div className="mt-5">
            <AppointmentCard
                title="Past Appointments"
                appointments={previousAppointments}
                handleCardClick={handleCardClick}
                activeIndex={activeIndex}
            />
        </div>
    );
};

export default PreviousAppointments;
