import Line from '../assets/images/Appointments/Line 3.svg';
import Date from '../assets/images/Appointments/Date2.svg';
import Time from '../assets/images/Appointments/Time.svg';
import Time2 from '../assets/images/Appointments/Time2.svg';
import Arrow from '../assets/images/Appointments/Arrow.png';
import Box from '../assets/images/Appointments/Box.png';
import Manage from '../assets/images/Appointments/Manage.png';
import ArrowRight from '../assets/images/Appointments/Arrow Right.svg';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { initializeChat } from '../redux/Chat/chatAction';
import { useDispatch } from 'react-redux';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import moment from 'moment-timezone';
import {
    convertTo12HourFormat,
    endTime,
    isReviewable,
} from '../helpers/constants';
import Loading from '../components/Loading';
import ManageAppointment from './Manage-Appointment';
import { reviewIcon } from '../assets/images/sidebarIcons';
import appointmentReducer from '../redux/appointments/appointmentReducer';

const AppointmentDetails = ({
    selectedAppointment,
    setSelectedAppointment,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isInitializingChat } = useSelector((state) => state.chat);
    const { userData } = useSelector((state) => state.user);
    const { isLoading } = useSelector((state) => state.appointments);

    const [businessDetails, setBusinessDetails] = useState(null);
    const [currentLatLng, setCurrentLatLng] = useState(null);
    const [businessLatLng, setBusinessLatLng] = useState(null);

    let business =
        selectedAppointment?.appointmentService[0]?.service?.business;
    const currentTimeZone = moment.tz.guess();

    const [isManageAppointment, setManageAppointment] = useState(false);
    const manageAppointmentToggle = () => {
        setManageAppointment(!isManageAppointment);
    };

    function getTotalDuration(items) {
        let totalDuration = 0;
        items.forEach((item) => {
            const durationStr = item?.service?.duration;
            const durationNum = parseInt(durationStr);
            totalDuration += durationNum;
        });
        return totalDuration;
    }

    const totalDuration = getTotalDuration(
        selectedAppointment?.appointmentService
    );

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLatLng({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error('Error obtaining location', error);
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    };

    const handleOpenMapDirection = () => {
        if (currentLatLng && businessLatLng) {
            const { latitude: lat1, longitude: lng1 } = currentLatLng;
            const { latitude: lat2, longitude: lng2 } = businessLatLng;

            const url = `https://www.google.com/maps/dir/?api=1&origin=${lat1},${lng1}&destination=${lat2},${lng2}`;

            window.open(url, '_blank');
        } else {
            toast.error(
                'Please enable your site location permission to get directions'
            );
        }
    };

    const handleChat = () => {
        let redirect =
            userData.role === 'customer' ? '/customer/chat' : '/dashboard/chat';
        dispatch(
            initializeChat(
                userData?.id,
                businessDetails?.user?.id,
                redirect,
                history
            )
        );
    };

    useEffect(() => {
        if (Array.isArray(selectedAppointment?.appointmentService)) {
            let business =
                selectedAppointment?.appointmentService[0]?.service?.business;
            setBusinessDetails(business);
            let latitude = parseFloat(business?.latitude);
            let longitude = parseFloat(business?.longitude);
            setBusinessLatLng({ latitude, longitude });
        }
    }, [selectedAppointment]);

    useEffect(() => {
        getLocation();
    }, []);

    const showCancellationImportantInfo = (type) => {
        if (
            selectedAppointment?.appointmentService?.length > 0 &&
            selectedAppointment?.appointmentService[0]?.service?.business
        ) {
            if (
                type == 'importantInfo' &&
                selectedAppointment?.appointmentService[0]?.service?.business
                    ?.policies
            ) {
                return true;
            } else if (
                type == 'cancellationType' &&
                selectedAppointment?.appointmentService[0]?.service?.business
                    ?.cancellationType
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const getCancellationText = () => {
        if (
            selectedAppointment?.appointmentService[0]?.service?.business?.cancellationType.toLowerCase() ==
            'strict'
        ) {
            return 'Please perform cancellation within 24 hours of appointment time to receive full refund.';
        } else if (
            selectedAppointment?.appointmentService[0]?.service?.business?.cancellationType.toLowerCase() ==
            'lenient'
        ) {
            return 'Please perform cancellation within 72 hours of appointment time to receive full refund';
        } else {
            return 'Please perform cancellation within 48 hours of appointment time to receive full refund.';
        }
    };

    return (
        <>
            {isLoading || !selectedAppointment || isInitializingChat ? (
                <div className="d-flex justify-content-center align-items-center">
                    {isInitializingChat ? (
                        <Loading />
                    ) : (
                        <Spin className="pt-40" />
                    )}
                </div>
            ) : (
                <div>
                    <div className="col-lg-12 image-container">
                        <img
                            src={
                                selectedAppointment?.appointmentService[0]
                                    ?.service?.business?.pictures[0]?.photo_path
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-between mx-2 mt-3 gap-2 flex-wrap">
                        <div>
                            <p className="poppins fw-medium f-20 mb-0">
                                {
                                    selectedAppointment?.appointmentService[0]
                                        ?.service?.business?.name
                                }
                            </p>
                            <div>
                                <img src={Date} />
                                <span className="mx-2 poppins f-14 c-667085 fw-400">
                                    {moment(selectedAppointment?.date).format(
                                        'DD/MM/YYYY'
                                    )}
                                </span>
                                <img src={Line} className="mx-2" />
                                <img src={Time2} />
                                <span className="mx-2 poppins f-14 c-667085 fw-400">
                                    {convertTo12HourFormat(
                                        selectedAppointment?.time
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <span className="confirmation-badge">
                                <span
                                    className="text-capitalize d-flex gap-2 align-items-center cp"
                                    onClick={handleChat}
                                >
                                    <h6 className="mb-0 poppins text-primary">
                                        Chat
                                    </h6>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                    >
                                        <path
                                            d="M5.71875 0.3125C4.26884 0.314092 2.87876 0.890774 1.85352 1.91602C0.828274 2.94126 0.251592 4.33134 0.25 5.78125V10.393C0.250289 10.6202 0.340668 10.838 0.501315 10.9987C0.661963 11.1593 0.879764 11.2497 1.10695 11.25H5.71875C7.16915 11.25 8.56015 10.6738 9.58574 9.64824C10.6113 8.62265 11.1875 7.23165 11.1875 5.78125C11.1875 4.33085 10.6113 2.93985 9.58574 1.91426C8.56015 0.88867 7.16915 0.3125 5.71875 0.3125ZM5.71875 10.375H1.125V5.78125C1.125 4.87269 1.39442 3.98454 1.89919 3.2291C2.40395 2.47366 3.1214 1.88487 3.9608 1.53718C4.8002 1.18949 5.72385 1.09852 6.61495 1.27577C7.50605 1.45302 8.32457 1.89053 8.96702 2.53298C9.60947 3.17543 10.047 3.99395 10.2242 4.88505C10.4015 5.77615 10.3105 6.6998 9.96282 7.5392C9.61513 8.3786 9.02634 9.09605 8.2709 9.60081C7.51546 10.1056 6.62731 10.375 5.71875 10.375Z"
                                            fill="#0059AB"
                                        />
                                    </svg>
                                </span>
                            </span>
                            <span className="confirmation-badge text-decoration-none">
                                <span
                                    className={`text-capitalize customer ${selectedAppointment?.status}`}
                                >
                                    {selectedAppointment?.status}
                                </span>
                            </span>
                            {isReviewable(selectedAppointment) && (
                                <Link
                                    to={{
                                        pathname: `/customer/review/${business?.id}`,
                                        state: {
                                            business: business?.id,
                                            appointment:
                                                selectedAppointment?.id,
                                        },
                                    }}
                                    className="review-btn cp text-decoration-none"
                                >
                                    <p className="text-capitalize mb-0">
                                        Review
                                    </p>
                                    <div>
                                        <img src={reviewIcon} alt="icon" />
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                    {!isReviewable(selectedAppointment?.status) && (
                        <div className="mt-4">
                            <div className="d-md-flex justify-content-between gap-3">
                                <div className="manage-appointment-card mb-3">
                                    {selectedAppointment && (
                                        <div className="mx-3">
                                            <AddToCalendarButton
                                                name={business?.name || ''}
                                                options={['Google', 'iCal']}
                                                location={
                                                    business?.address || ''
                                                }
                                                startDate={moment(
                                                    selectedAppointment?.date
                                                ).format('YYYY-MM-DD')}
                                                endDate={moment(
                                                    selectedAppointment?.date
                                                ).format('YYYY-MM-DD')}
                                                startTime={moment(
                                                    selectedAppointment?.time,
                                                    'HH:mm'
                                                ).format('HH:mm A')}
                                                endTime={moment(
                                                    selectedAppointment?.time,
                                                    'HH:mm'
                                                )
                                                    .add(
                                                        totalDuration,
                                                        'minutes'
                                                    )
                                                    .format('HH:mm A')}
                                                timeZone={currentTimeZone}
                                                label="Add to calendar"
                                                description={`Services Paid For:\n${selectedAppointment?.appointmentService
                                                    .map((service) => {
                                                        let name =
                                                            service?.service
                                                                ?.name;
                                                        let category =
                                                            service?.service
                                                                ?.category;
                                                        let description =
                                                            service?.service
                                                                ?.description;
                                                        return ` \t Name: ${name}  \n \t Category: ${category} \n \t Description: ${description} \n`;
                                                    })
                                                    .join('\n')}`}
                                            />
                                            <span className="description f-14">
                                                Set yourself a reminder
                                            </span>
                                        </div>
                                    )}
                                    <div>
                                        <img
                                            src={ArrowRight}
                                            className="manage-appointment-card-arrow"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="manage-appointment-card mb-3 cp"
                                    onClick={handleOpenMapDirection}
                                >
                                    <div className="d-flex align-items-center cp">
                                        <span>
                                            <img src={Arrow} />
                                        </span>
                                        <div className="mx-3">
                                            <div className="heading f-16">
                                                Getting there
                                            </div>
                                            <span className="description f-14">
                                                {businessDetails?.address}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={ArrowRight}
                                            className="manage-appointment-card-arrow"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-flex justify-content-between gap-3">
                                <div
                                    className="manage-appointment-card mb-3 cp"
                                    onClick={manageAppointmentToggle}
                                >
                                    <div className="d-flex align-items-center">
                                        <span>
                                            <img src={Manage} />
                                        </span>
                                        <div className="mx-3">
                                            <div className="heading f-16">
                                                Manage appointment
                                            </div>
                                            <span className="description f-14">
                                                Reschedule or cancel your
                                                appointment
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={ArrowRight}
                                            className="manage-appointment-card-arrow"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="manage-appointment-card mb-3 cp"
                                    onClick={() =>
                                        history.push(
                                            `/business-details/${businessDetails?.id}`
                                        )
                                    }
                                >
                                    <div className="d-flex align-items-center">
                                        <span>
                                            <img src={Box} />
                                        </span>
                                        <div className="mx-3">
                                            <div className="heading f-16">
                                                Venue details
                                            </div>
                                            {/* <span className="description f-14">
                                                You Do You Hair Studio
                                            </span> */}
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={ArrowRight}
                                            className="manage-appointment-card-arrow"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="overview mt-4">
                        <p className="heading f-20">Overview</p>
                        <div className="Appointment-detail">
                            {selectedAppointment?.appointmentService?.map(
                                (item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="d-flex justify-content-between mt-1 align-items-start py-2">
                                                <span className="f-14 poppins c-667085 gap-2">
                                                    <div className="poppins f-16 fw-medium mb-2">
                                                        {item?.service?.name}
                                                    </div>
                                                    <img src={Time} />{' '}
                                                    <span>
                                                        {
                                                            item?.service
                                                                ?.duration
                                                        }
                                                    </span>
                                                </span>
                                                <span className="poppins f-16 fw-medium">
                                                    £{item?.service?.price}
                                                </span>
                                            </div>
                                            <hr />
                                        </React.Fragment>
                                    );
                                }
                            )}
                            <div className="d-flex justify-content-between">
                                <span className="poppins f-16 fw-medium">
                                    Total
                                </span>
                                <span className="poppins f-16 fw-medium">
                                    £{selectedAppointment?.amount}
                                </span>
                            </div>
                        </div>
                    </div>

                    {showCancellationImportantInfo('cancellationType') && (
                        <div className="mt-4">
                            <p className="cancellation-heading f-20">
                                Cancellation Policy
                            </p>
                            <p className="f-16 c-667085 fw-400 poppins">
                                {getCancellationText()}
                                {/* Please avoid cancelling within 6 hours of your
                            appointment time */}
                            </p>
                        </div>
                    )}

                    {showCancellationImportantInfo('importantInfo') && (
                        <div>
                            <p className="info-heading f-20">Important info</p>
                            <p className="f-16 c-667085 fw-400 poppins">
                                {
                                    selectedAppointment?.appointmentService[0]
                                        ?.service?.business?.policies
                                }
                                {/* Thank you for booking with us. Incase you’d like to
                            reschedule or cancel your appointment please call us
                            on{' '}
                            <span className="white-space-no-wrap">
                                (415) 555‑0132
                            </span> */}
                            </p>
                        </div>
                    )}
                </div>
            )}

            <ManageAppointment
                isOpen={isManageAppointment}
                toggle={manageAppointmentToggle}
                selectedAppointment={selectedAppointment}
                setSelectedAppointment={setSelectedAppointment}
            />
        </>
    );
};

export default AppointmentDetails;
