import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Service = lazy(() => import('../../container/service'));
const EditService = lazy(() => import('../../container/service/editService'));
const AddService = lazy(() => import('../../container/service/addService'));

function ServiceRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/edit-service/:id`} component={EditService} />
            <Route path={`${path}/add-service`} component={AddService} />
            <Route path={path} component={Service} />
        </Switch>
    );
}

export default ServiceRoutes;
