import React from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'

const BusinessTerms = () => {
  return (
    <div className={`landing-page-body`}>

      <div className="header-bg">
        <Header />
      </div>
      <div className={`container`}>
        <h2 className={`my-5`}>Tisume Terms and Conditions</h2>
        <div>
          <h4>1. Introduction</h4>
          <p>Welcome to Tisume. By using our platform, you agree to comply with and be bound by the
            following terms and conditions.</p>
        </div>
        <div>
          <h4>2. Company Information</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li><span className='fw-semibold'>Full Legal Name: </span>  Tisume Ltd.</li>
            <li><span className='fw-semibold'>Company Registration: </span>  Registered in [Your Country], with registration number [Your
              Company Registration Number].</li>
          </ul>
        </div>
        <div>
          <h4>3. User Responsibilities</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>Users must provide accurate information, respect the platform’s guidelines, and use
              the platform for lawful purposes.</li>
            <li>Users must not engage in fraudulent activities or misuse the platform.</li>
            <li>Users must be 16 years or older to use the platform.</li>
          </ul>
        </div>
        <div>
          <h4>4. Account Registration</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Required Information:  </span>
              Users need to provide their full name, email address, phone
              number, and a secure password. Service providers may need to provide additional
              information such as business name, address, and proof of certifications.</li>
            <li><span className='fw-semibold'>Verification:  </span> Users receive an email to verify their account. Service providers may
              undergo an additional vetting process, including validation of their credentials and
              business legitimacy.</li>
          </ul>
        </div>
        <div>
          <h4>5. Service Provision</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li><span className='fw-semibold'>Terms for Providers: </span>  Providers agree to keep their availability, pricing, and service
              details up-to-date. They are responsible for the quality of their services and
              complying with relevant regulations.</li>
            <li><span className='fw-semibold'>Booking and Payment: </span> Users can book services through the platform and make
              payments online. Providers receive payments through the platform minus a service
              fee.</li>
            <li><span className='fw-semibold'>Cancellations and Refunds: </span> Cancellations and refunds are dependent on the
              individual service provider’s notice period policy. Providers must clearly communicate
              their cancellation policies to clients at the time of booking. For late cancellations or
              no-shows, automatic charges may apply according to the provider’s policy </li>
          </ul>
        </div>
        <div>
          <h4>6. Intellectual Property</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li><span className='fw-semibold'>Content Ownership: </span>  Content created by Tisume (e.g., logos, platform design) is
              owned by Tisume. User-generated content remains the property of the users but
              grants Tisume a non-exclusive license to use it for promotional purposes.</li>
            <li><span className='fw-semibold'>Proper Use: </span>  Users can share content on social media but must credit Tisume and
              not alter the content in misleading ways.</li>
          </ul>
        </div>
        <div>
          <h4>7. Media and Photos</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li><span className='fw-semibold'>Rights to Photos: </span>  Service providers often take photos of their clients and post them
              on social media platforms like Instagram. By using Tisume, service providers agree
              to also post these photos on Tisume. Clients must provide consent for their photos to
              be used by service providers and Tisume for promotional purposes. Tisume will
              include a feature within the platform for clients to provide explicit consent during the
              booking or appointment process.</li>

          </ul>
        </div>
        <div>
          <h4>8. Liability</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Limitations: </span>
              Tisume is not liable for any direct or indirect damages resulting from the
              use of the platform, including disputes between users and providers. Tisume’s liability
              is limited to the amount paid by the user for the services in dispute. </li>
            <li>
              <span className='fw-semibold'>Dispute Handling: </span>
              Tisume provides a dispute resolution process but is not
              responsible for the actual service provided by the beauty professionals. Tisume will
              facilitate mediation and provide clear guidelines for handling disputes. </li>
          </ul>
        </div>
        <div>
          <h4>9. Amendments</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Notification of Changes: </span>
              Tisume will notify users of any changes to the Terms and
              Conditions via email and a notice on the platform. Continued use of the platform after
              changes implies acceptance.  </li>
          </ul>
        </div>
        <div>
          <h4>10. System Reliability</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Technical Infrastructure: </span>
              Tisume will maintain a robust technical infrastructure with
              regular updates and backups. A contingency plan will be in place to handle system
              outages, and 24/7 customer support will be available to address technical issues
              promptly.  </li>
          </ul>
        </div>
        <div>
          <h4>11. Service Provider Cancellations</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Cancellation Policy: </span>
              Service providers must clearly communicate their cancellation
              policies at the time of booking. Tisume will implement a policy that includes penalties
              for frequent cancellations by providers. A rating and feedback system will be in place
              to monitor provider reliability.  </li>
          </ul>
        </div>
        <div>
          <h4>12. Payment Processing</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Timely Payments: </span>
              Tisume will ensure timely and accurate payment processing.
              Payment schedules and procedures will be clearly outlined, and a dedicated support
              channel will be available for addressing any payment issues. Tisume will establish
              relationships with multiple payment processors to avoid single points of failure.  </li>
          </ul>
        </div>
        <div>
          <h4>13. Marketing and Promotions</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Promotional Tools: </span>
              Tisume will provide tools for service providers to create and
              manage promotions. Guidelines and tracking tools will be available to ensure
              promotions are fair and transparent.  </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default BusinessTerms