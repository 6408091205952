import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../helpers/functions";

const AuthenticatedCustomerRoute = ({ children, ...rest }) => {
  const { userData } = useSelector((state) => state.user);

  const isAuthenticatedCustomer = userData?.role === "customer";

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() && isAuthenticatedCustomer ? (
          children
        ) : isAuthenticated() && !isAuthenticatedCustomer ? (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedCustomerRoute;
