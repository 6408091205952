import React, { useState } from 'react';
import './create-account.css';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { customerSignUp } from '../../redux/authentication/actionCreator';
import { useSelector } from 'react-redux';
import {
    alphabetsRegex,
    numericRegex,
    options,
    validateEmail,
    validatePassword,
} from '../../helpers/constants';
import AuthLayout from '../../container/auth/auth-layout';
import logo from '../../assets/images/Appointments/Logo-Header.png';

const RegisterForEveryone = () => {
    const isLoading = useSelector((state) => state.auth.loading);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();


    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get('referral');

    const [toggleScreen, setToggleScreen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [countryCode, setCountryCode] = useState('+44');
    const [terms, setTerms] = useState(false);
    const [mails, setMails] = useState(false);
    const [errors, setErrors] = useState({});

    const fullName = `${firstName.trim()} ${lastName.trim()}`;
    const fullNumber = `${countryCode}-${phone.trim()}`;

    const handleCountryCodeClick = (_country) => {
        setCountryCode(_country.code);
    };

    const filteredOptions = options.filter((country) =>
        country.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputs = (e) => {
        const { name, value, checked } = e.target;

        const updateErrors = (errorCondition, errorMessage) => {
            setErrors((prev) => ({
                ...prev,
                [name]: errorCondition ? errorMessage : '',
            }));
        };

        switch (name) {
            case 'phone':
                if (numericRegex.test(value)) {
                    setPhone(value);
                    updateErrors(!value, 'Enter your mobile number');
                }
                break;
            case 'firstName':
            case 'lastName':
                if (alphabetsRegex.test(value)) {
                    if (name === 'firstName') {
                        setFirstName(value);
                        updateErrors(!value, `Enter your first name`);
                    } else {
                        setLastName(value);
                        updateErrors(!value, `Enter your last name`);
                    }
                }
                break;
            case 'terms':
                setTerms(checked);
                updateErrors(!checked, 'You have to agree to proceed');
                break;
            case 'mails':
                setMails(checked);
                break;
            default:
                break;
        }
    };

    const handleCredentials = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
            if (validateEmail(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        } else if (name === 'password') {
            setPassword(value);
            if (validatePassword(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        }
    };

    const handleTermsClick = (url) => {
        window.open(url, '_blank');
    };

    const handleToggleScreen = async (e) => {
        e.preventDefault();
        setErrors({});
        const [isEmailValid, isPasswordValid] = await Promise.all([
            validateEmail(email, setErrors),
            validatePassword(password, setErrors),
        ]);
        if (isEmailValid && isPasswordValid) {
            setToggleScreen(true);
        }
    };

    const CustomerSignUp = async (e) => {
        e.preventDefault();
        setErrors({});
        const updateError = (field, message) => {
            setErrors((prev) => ({ ...prev, [field]: message }));
        };
        if (!firstName) {
            updateError('firstName', 'Enter your first name');
        }
        if (!lastName) {
            updateError('lastName', 'Enter your last name');
        }
        if (!phone) {
            updateError('phone', 'Mobile number is required');
        } else if (phone.length < 6 || phone.length > 14)
            updateError(
                'phone',
                'Mobile number must be between 6 to 14 digits'
            );
        if (!terms) updateError('terms', 'You have to agree to proceed');
        if (
            !firstName ||
            !lastName ||
            !phone ||
            !terms ||
            phone.length < 6 ||
            phone.length > 14
        )
            return;

        dispatch(
            customerSignUp(
                { email, password, phone: fullNumber, name: fullName }, referral,
                history
            )
        );
    };

    return (
        <AuthLayout>
            <Link className="d-flex justify-content-center mb-4" to="/home">
                <img src={logo} alt="Logo" style={{ width: '132px' }} />
            </Link>
            <div className={`col-12 px-0 `}>
                <h5
                    className={`c-334155 poly f-36 fw-400 lh-48 m-0 text-center`}
                >
                    Tisume for customers
                </h5>
            </div>
            <div className={`col-12 px-0 pb-md-30 pb-3`}>
                <h6
                    className={`c-667085 poppins f-14 fw-400 lh-20 m-0 text-center`}
                >
                    Create an account to book an appointment
                </h6>
            </div>

            {!toggleScreen ? (
                <form onSubmit={handleToggleScreen}>
                    <div className={`col-12 px-md-0 position-relative`}>
                        <div className="mb-20">
                            <label
                                htmlFor="email"
                                className="form-label c-667085 poppins f-14 fw-400 lh-20"
                            >
                                Email Address{' '}
                                <span className={`text-danger`}>*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control input-filed"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleCredentials}
                                placeholder="Your Email"
                            />
                            {errors.email && (
                                <p className="text-danger fs-12">
                                    {errors.email}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={`col-12 px-md-0 position-relative`}>
                        <div className="mb-20">
                            <label
                                htmlFor="password"
                                className="form-label c-667085 poppins f-14 fw-400 lh-20"
                            >
                                Password{' '}
                                <span className={`text-danger`}>*</span>
                            </label>
                            <input
                                type="password"
                                className="form-control input-filed"
                                id="password"
                                name="password"
                                value={password}
                                onChange={handleCredentials}
                                placeholder="Your Password"
                            />
                            {errors.password && (
                                <p className="text-danger fs-12">
                                    {errors.password}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={`col-12 px-md-0 mt-2`}>
                        <button
                            className={`btn btn-create-acc mb-20`}
                            type="submit"
                        >
                            Continue
                        </button>
                        <Link to={'/login-customer'}
                            style={{ paddingTop: '11px', paddingBottom: '11px' }}
                            className={`btn btn-outline mb-20 border rounded-pill w-100`}
                            disabled={isLoading}
                        >
                            Login
                        </Link>
                    </div>
                </form>
            ) : (
                <form onSubmit={CustomerSignUp}>
                    <div className={`col-12 px-md-0 position-relative`}>
                        <div className="mb-20">
                            <label
                                htmlFor="firstName"
                                className="form-label c-667085 poppins f-14 fw-400 lh-20"
                            >
                                First Name{' '}
                                <span className={`text-danger`}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control input-filed"
                                id="firstName"
                                name="firstName"
                                value={firstName}
                                onChange={handleInputs}
                                placeholder="Your First Name"
                            />
                            {errors.firstName && (
                                <p className="text-danger fs-12">
                                    {errors.firstName}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={`col-12 px-md-0 position-relative`}>
                        <div className="mb-20">
                            <label
                                htmlFor="lastName"
                                className="form-label c-667085 poppins f-14 fw-400 lh-20"
                            >
                                Last Name{' '}
                                <span className={`text-danger`}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control input-filed"
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                onChange={handleInputs}
                                placeholder="Your Last Name"
                            />
                            {errors.lastName && (
                                <p className="text-danger fs-12">
                                    {errors.lastName}
                                </p>
                            )}
                        </div>

                        <div
                            className={`col-12 px-md-0 position-relative mb-20`}
                        >
                            <label
                                htmlFor="phone"
                                className="form-label c-667085 poppins f-14 fw-400 lh-20"
                            >
                                Mobile number{' '}
                                <span className={`text-danger`}>*</span>
                            </label>
                            <div className="input-group input-filed">
                                <span
                                    className="input-group-text border-y-0 border-l-0 border-r-e1e6ef py-0 ps-0 bg-transparent"
                                    id="basic-addon1"
                                >
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-country-num dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div className={`container-fluid`}>
                                                <div className={`row`}>
                                                    <div className={`col px-0`}>
                                                        {countryCode}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                        <div className="dropdown-menu country-dropdown-menu">
                                            <input
                                                className={`country-dropdown-input`}
                                                type="text"
                                                placeholder="Search for a country..."
                                                value={searchTerm}
                                                onChange={(e) =>
                                                    setSearchTerm(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {filteredOptions.map((_country) => (
                                                <p
                                                    key={_country.label}
                                                    className="dropdown-item cp"
                                                    onClick={() =>
                                                        handleCountryCodeClick(
                                                            _country
                                                        )
                                                    }
                                                >
                                                    {_country.flag}{' '}
                                                    {_country.label}{' '}
                                                    {_country.code}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </span>
                                <input
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    onChange={handleInputs}
                                    className="form-control border-0 py-0 box-shadow-0"
                                    placeholder="Your Number"
                                />
                            </div>
                            {errors.phone && (
                                <p className="text-danger fs-12">
                                    {errors.phone}
                                </p>
                            )}
                        </div>
                    </div>

                    <div
                        className={`col-12 px-md-0 position-relative mb-md-82 mb-4`}
                    >
                        <div className="form-check-label d-flex ps-0 pt-lg-0 pt-4px cp">
                            <div>
                                <input
                                    className="form-check-input check-box-custom cp"
                                    type="checkbox"
                                    name="terms"
                                    value={terms}
                                    checked={terms}
                                    onClick={handleInputs}
                                />
                            </div>
                            <div>
                                <span>
                                    I agree to the{' '}
                                    <span
                                        className={`c-667085 poppins f-14 fw-medium lh-24`}
                                        onClick={() =>
                                            handleTermsClick('/privacy-policy')
                                        }
                                    >
                                        Privacy Policy
                                    </span>{' '}
                                    {/* <span
                                        className={`c-667085 poppins f-14 fw-medium lh-24`}
                                        onClick={() =>
                                            handleTermsClick('/terms')
                                        }
                                    >
                                        Terms of Services
                                    </span>{' '} */}
                                    and{' '}
                                    <span
                                        className={`c-667085 poppins f-14 fw-medium lh-24`}
                                        onClick={() =>
                                            handleTermsClick('/business-terms')
                                        }
                                    >
                                        Terms of use.
                                    </span>
                                </span>
                            </div>
                        </div>
                        {errors.terms && (
                            <p className="text-danger fs-12">{errors.terms}</p>
                        )}

                        <div className="form-check-label d-flex ps-0 pt-lg-0 pt-4px cp mt-20">
                            <div>
                                <input
                                    className="form-check-input check-box-custom cp"
                                    type="checkbox"
                                    name="mails"
                                    value={mails}
                                    checked={mails}
                                    onClick={handleInputs}
                                />
                            </div>
                            <div>
                                <span>
                                    I agree to receive marketing notifications
                                    with offers and news
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={`col-12 px-md-0 mt-2`}>
                        <button
                            className={`btn btn-create-acc mb-20`}
                            disabled={isLoading}
                            type="submit"
                        >
                            {isLoading ? <Spin /> : 'Create Account'}
                        </button>
                        <Link to={'/login-customer'}
                            style={{ paddingTop: '11px', paddingBottom: '11px' }}
                            className={`btn btn-outline mb-20 border rounded-pill w-100`}
                            disabled={isLoading}
                        >
                            Login
                        </Link>
                    </div>
                </form>
            )}

            <div className="col-12 px-md-0 pb-lg-0 pb-5">
                <h6 className="m-0 c-667085 poppins f-14 f-mobile-16 fw-normal lh-20 text-center">
                    <Link
                        to="/register"
                        className="c-334155 poppins f-14 f-mobile-16 lh-20 ms-2"
                    >
                        Sign up/Sign In as a Business{' '}
                    </Link>
                </h6>
            </div>
        </AuthLayout>
    );
};

export default RegisterForEveryone;
