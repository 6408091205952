import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

const PrivacyPolicy = () => {
  return (
    <div className={`landing-page-body`}>
      <div className="header-bg">
        <Header />
      </div>
      <div className={`container`}>
        <h2 className={`my-5`}>Tisume Privacy Policy</h2>
        <div>
          <h4>1. Introduction</h4>
          <p>At Tisume, we are committed to protecting and respecting your privacy. This Privacy Policy
            explains how we collect, use, and safeguard your personal data.</p>
        </div>
        <div>
          <h4>2. Data Controller</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Data Controller: </span>
              Tisume Ltd. is your Data Controller and responsible for your
              personal data. For any inquiries, please contact us at support@tisume.com.
            </li>
          </ul>
        </div>
        <div>
          <h4>3. Data Collection</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Types of Data: </span>
              Personal data (name, email, phone number), booking details,
              payment information, and social media profile information (if linked).
            </li>
            <li>
              <span className='fw-semibold'>Collection Methods: </span>
              Data is collected through account registration, booking forms,
              cookies, and third-party integrations (e.g., social media logins).
            </li>
          </ul>
        </div>
        <div>
          <h4>4. Data Use</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Usage Purposes: </span>
              Data is used to facilitate bookings, process payments, improve
              user experience, send marketing communications (with consent), and comply with
              legal obligations.
            </li>
            <li>
              <span className='fw-semibold'>Marketing: </span>
              Users can opt-in to receive newsletters and promotional materials. Data
              from marketing interactions is used to tailor future communications.
            </li>
          </ul>
        </div>
        <div>
          <h4>5. Data Sharing</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Third-Party Sharing: </span>
              Data is shared with payment processors, email service
              providers, and social media platforms (for login and marketing). All third parties are
              required to comply with data protection standards.
            </li>
            <li>
              <span className='fw-semibold'>International Transfer: </span>
              Data may be transferred to countries outside the user’s
              jurisdiction, but always with adequate safeguards in place.
            </li>
          </ul>
        </div>
        <div>
          <h4>6. Data Security</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Protection Measures: </span>
              Data is encrypted during transmission and storage. Regular
              security audits are conducted, and access to data is restricted to authorized
              personnel.
            </li>
            <li>
              <span className='fw-semibold'>Data Breaches: </span>
              Users are notified within 72 hours of any data breach that may
              affect their personal data. Tisume has a detailed data breach response plan,
              including notifying affected users and providing support such as credit monitoring
              services.
            </li>
          </ul>
        </div>
        <div>
          <h4>7. User Rights</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Rights: </span>
              Users can access, correct, delete their data, and object to data processing.
              They can also request data portability and restrict processing in certain
              circumstances.
            </li>
            <li>
              <span className='fw-semibold'>Exercising Rights: </span>
              Users can contact Tisume’s support team via email to exercise
              their rights.
            </li>
          </ul>
        </div>
        <div>
          <h4>8. Data Retention</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Retention Period: </span>
              Personal data is retained for as long as necessary to provide
              services and fulfill legal obligations. Data from inactive accounts is deleted after two
              years.

            </li>
            <li>
              <span className='fw-semibold'>Deletion Requests: </span>
              Users can request deletion of their data, which Tisume will
              process within a reasonable timeframe, unless retention is required by law.
            </li>
          </ul>
        </div>
        <div>
          <h4>9. Cookies and Tracking</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Usage: </span>
              Tisume uses cookies to enhance user experience, analyze site usage, and
              deliver personalized ads. Users are informed about cookies upon their first visit and
              can manage their preferences.

            </li>
            <li>
              <span className='fw-semibold'>Informing Users: </span>
              A detailed cookie policy is available on the website, explaining the
              types of cookies used and their purposes.
            </li>
          </ul>
        </div>
        <div>
          <h4>10. Children’s Privacy</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Data Collection from Minors: </span>
              Tisume does not knowingly collect data from children
              under 16. If discovered, such data is deleted immediately.
            </li>
            <li>
              <span className='fw-semibold'>Protection Measures: </span>
              Parental consent is required for users under 18. Tisume will
              implement an age verification process during account creation.
            </li>
          </ul>
        </div>
        <div>
          <h4>11. Specific Scenarios</h4>
          <ul style={{ listStyle: 'numaric' }}>
            <li>
              <span className='fw-semibold'>Service Provider Promotions: </span>
              <ul style={{ listStyle: 'circle' }}>
                <li>
                  <span className='fw-semibold'>Terms: </span>
                  Providers can run promotions but must adhere to Tisume’s guidelines
                  to ensure transparency and fairness. All promotions must be clearly
                  communicated and honor all bookings made during the promotion period.
                </li>
              </ul>
            </li>
            <li>
              <span className='fw-semibold'>Dispute Resolution: </span>
              <ul style={{ listStyle: 'circle' }}>
                <li>
                  <span className='fw-semibold'>Process: </span>
                  Users can report issues via the platform’s support system. Tisume
                  facilitates communication between the user and provider but does not
                  guarantee resolution. A comprehensive dispute resolution process, including
                  mediation, will be in place.
                </li>
              </ul>
            </li>
            <li>
              <span className='fw-semibold'>Cross-Promotion and Partnerships: </span>
              <ul style={{ listStyle: 'circle' }}>
                <li>
                  <span className='fw-semibold'>Terms: </span>
                  Tisume engages in cross-promotion with other brands and influencers
                  under strict agreements to protect user data. Users are informed of such
                  partnerships and can opt-out.
                </li>
                <li>
                  <span className='fw-semibold'>Data Protection: </span>
                  All partners are required to adhere to Tisume’s data
                  protection standards.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <h4>12. Legal Compliance and User Communication</h4>
          <ul style={{ listStyle: 'numaric' }}>
            <li>
              <span className='fw-semibold'>Legal Compliance: </span>
              <ul style={{ listStyle: 'circle' }}>
                <li>
                  <span className='fw-semibold'>Regulations:  </span>
                  Tisume complies with GDPR, CCPA, and other relevant data
                  protection regulations. A Data Protection Officer (DPO) and legal team are
                  appointed to ensure compliance with regulations.

                </li>
                <li>
                  <span className='fw-semibold'>Policy Updates: </span>
                  Policies will be regularly reviewed and updated to reflect
                  changes in the law.
                </li>
              </ul>
            </li>
            <li>
              <span className='fw-semibold'>User Communication: </span>
              <ul style={{ listStyle: 'circle' }}>
                <li>
                  <span className='fw-semibold'>Communication Channels: </span>
                  Tisume uses email, in-app notifications, and the
                  website to communicate with users about their data and privacy rights.

                </li>
                <li>
                  <span className='fw-semibold'>Opt-In/Opt-Out: </span>
                  Users can manage their communication preferences through
                  their account settings.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <h4>13. Notification of Changes and Acceptance of Policy</h4>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              <span className='fw-semibold'>Updates: </span>
              Tisume will update this Privacy Policy periodically. Users will be notified of
              significant changes via email and notices on the platform. Continued use of Tisume
              implies acceptance of the updated policy.
            </li>
          </ul>
        </div>
        <div>
          <h4>14. Contact Information</h4>
          <p>For any questions or concerns about these Terms and Conditions or Privacy Policy, please
            contact us at support@tisume.com</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
