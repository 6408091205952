import React, { useState } from 'react';
import Axios from '../../config/Axios';
import { isArray } from '../../helpers/functions';
import AuthLayout from './auth-layout';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Appointments/Logo-Header.png';
import { Spin } from 'antd';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setErrors({});
            setIsLoading(true);
            const response = await Axios.post('auth/forgot-password', {
                email,
            });
            setIsLoading(false);
            setMessage(response.data);
        } catch (error) {
            setIsLoading(false);
            const updatedFieldErrors = {};
            const data = error?.response?.data;

            if (isArray(data?.message)) {
                data?.message?.forEach((error) => {
                    updatedFieldErrors[error.field] = error.message;
                });
            } else {
            }

            setErrors(updatedFieldErrors);
        }
    };

    return (
        <AuthLayout>
            <Link className="d-flex justify-content-center mb-4" to="/home">
                <img src={logo} alt="Logo" style={{ width: '132px' }} />
            </Link>
            <div className={`col-12 px-0 pt-lg-0 pt-5`}>
                <h5
                    className={`c-334155 poly f-36 fw-400 lh-48 m-0 text-center`}
                >
                    Forget Your Password?
                </h5>
            </div>

            <div className={`col-12 px-0 pb-md-30 pb-3`}>
                <h6
                    className={`c-667085 poppins f-14 fw-400 lh-20 m-0 text-center`}
                >
                    please fill the information below
                </h6>
            </div>

            {message && (
                <div className={`centered-container`}>
                    <div className={`success-badge mb-2`}>
                        <span>{message}!</span>
                    </div>
                </div>
            )}

            <div className={`col-12 px-md-0 position-relative`}>
                <div className="mb-20">
                    <label
                        htmlFor="email"
                        className="form-label c-667085 poppins f-14 fw-400 lh-20"
                    >
                        Email Id <span className={`text-danger`}>*</span>
                    </label>
                    <input
                        type="email"
                        className="form-control input-filed"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email"
                    />

                    {errors.email && (
                        <p className={`password-error-text`}>{errors.email}</p>
                    )}
                </div>
            </div>

            <div className={`col-12 px-md-0`}>
                <button
                    className={`btn btn-create-acc mb-20`}
                    onClick={handleSubmit}
                >
                    {isLoading ? <Spin /> : 'Send Mail'}
                </button>
            </div>
        </AuthLayout>
    );
};
export default ForgetPassword;
