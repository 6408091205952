import React, { useState } from 'react';
import Date from '../../assets/images/services/Date.svg';
import './services.css';
import { useSelector } from 'react-redux';
import {
    businessAverageRating,
    convertTo12HourFormat,
    isBoolean,
    options,
    shortToFullDayMap,
} from '../../helpers/constants';
import { useEffect } from 'react';
import { Spin } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';

const SelectTimeContinue = ({
    selectedDate,
    selectedSlots,
    activeTab,
    setActiveTab,
    currentDay,
    bookAppointment,
    phone,
    setPhone,
    countryCode,
    setCountryCode,
    handleBooking,
    cardId,
}) => {
    const { singleBusinessDetails, selectedServices } = useSelector(
        (state) => state.services
    );
    const { userData } = useSelector((state) => state.user);
    const { isLoading } = useSelector((state) => state.appointments);
    const [showPolicy, setShowPolicy] = useState(true);


    const [searchTerm, setSearchTerm] = useState('');

    const totalPrice =
        selectedServices &&
        selectedServices.reduce((total, service) => {
            return total + Number(service.price);
        }, 0);

    const filteredOptions = options.filter((country) =>
        country.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCountryCodeClick = (_country) => {
        setCountryCode(_country.code);
    };

    const currentDayShort = currentDay.toString().split(' ')[0];
    const fullDayName = shortToFullDayMap[currentDayShort];
    const openingTime = singleBusinessDetails?.openingTimes && singleBusinessDetails?.openingTimes[fullDayName];

    useEffect(() => {
        setCountryCode(userData?.phone.split('-')[0]);
        setPhone(userData?.phone.split('-')[1]);
    }, []);
    return (
        <div>
            <div>
                <div className="p-4 mt-3 continue-card">
                    <div className="d-flex gap-3">
                        <div>
                            <img
                                src={
                                    singleBusinessDetails.pictures[0].photo_path
                                }
                                className="profile-img"
                            />
                        </div>
                        <div>
                            <p className="m-0 fs-18  c-0f151d poppins fw-600">
                                {singleBusinessDetails?.name}
                            </p>
                            <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                                <div className="m-0 fs-16  c-667085 poppins mt-1">
                                    {moment(currentDay.toDateString()).format(
                                        'DD/MM/YYYY'
                                    )}
                                </div>
                                <div className="m-0 fs-16  c-667085 poppins mt-1">
                                    {isBoolean(openingTime?.isOpened)
                                        ? convertTo12HourFormat(
                                              openingTime?.from
                                          )
                                        : ''}
                                </div>
                            </div>
                            <div className="d-flex justify-content-start align-items-center mt-2">
                                <h6
                                    className={`m-0 c-0f151d poppins fs-12 fw-normal lh-16`}
                                >
                                    {businessAverageRating(
                                        singleBusinessDetails?.reviews || []
                                    )}
                                </h6>
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mx-1"
                                >
                                    <g clipPath="url(#clip0_252_296)">
                                        <path
                                            d="M2.44884 11.6623C2.40084 11.6623 2.35284 11.6383 2.30484 11.6143C2.23284 11.5663 2.18484 11.4463 2.20884 11.3503L3.26484 7.41428L0.0968438 4.84628C0.000843818 4.79828 -0.0231562 4.67828 0.000843815 4.58228C0.0248438 4.48628 0.120844 4.41428 0.216844 4.41428L4.29684 4.19828L5.76084 0.382281C5.80884 0.310281 5.90484 0.238281 6.00084 0.238281C6.09684 0.238281 6.19284 0.310281 6.21684 0.382281L7.68084 4.19828L11.7608 4.41428C11.8568 4.41428 11.9528 4.48628 11.9768 4.58228C12.0008 4.67828 11.9768 4.77428 11.9048 4.84628L8.73684 7.41428L9.79284 11.3503C9.81684 11.4463 9.79284 11.5423 9.69684 11.6143C9.62484 11.6623 9.50484 11.6863 9.43284 11.6143L6.00084 9.40628L2.56884 11.6143C2.52084 11.6623 2.49684 11.6623 2.44884 11.6623Z"
                                            fill="#334155"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_252_296">
                                            <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6
                                    className={`m-0  c-0f151d poppins fs-12 fw-normal lh-16`}
                                >
                                    (
                                    {singleBusinessDetails?.reviews.length || 0}
                                    )
                                </h6>
                            </div>
                        </div>
                    </div>
                    <p className="f-18 poppins mt-3 fw-600 ">Overview</p>
                    {selectedServices && selectedServices.length > 0 && (
                        <div className="mt-2">
                            {selectedServices.map((item, i) => (
                                <div
                                    className="d-flex justify-content-between"
                                    key={i}
                                >
                                    <div>
                                        <div className="f-18 poppins fw-600 ">
                                            {item?.name}
                                        </div>
                                        <div className="f-16 c-667085 poppins">
                                            {item?.duration}
                                        </div>
                                    </div>
                                    <div className="fw-600 f-16 poppins">
                                        £{item?.price}
                                    </div>
                                </div>
                            ))}
                            <hr />
                            <div className="d-flex justify-content-between">
                                <span className="fw-600 f-16 poppins">
                                    Total
                                </span>
                                <span className="fw-600 f-16 poppins">
                                    £{totalPrice}
                                </span>
                            </div>
                        </div>
                    )}
                    {selectedSlots ? (
                        <div className="appointment-time mt-4">
                            <div className="heading">Appointment</div>
                            <div className="d-flex mt-3">
                                <img src={Date} />
                                <div className="mx-2 ">
                                    <div className="professional fs-16">
                                        {moment(
                                            `${selectedDate?.date}-${selectedDate?.month}-${selectedDate?.year}`
                                        ).format('DD/MM/YYYY')}
                                    </div>
                                    <div className="assigned fs-14">
                                        {convertTo12HourFormat(
                                            selectedSlots.split('-')[0]
                                        )}
                                        {/* {' '}
                                        {convertTo12HourFormat(
                                            selectedSlots.split('-')[1]
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>
                            Please choose your prefered date and time and click
                            the "continue" button to proceed to the next step.
                        </p>
                    )}
                    <button
                        type="button"
                        className="f-16 poppins text-white booking-btn w-100 mt-4"
                        onClick={() => {
                            if (activeTab === 'payment') {
                                if (cardId) {
                                    handleBooking();
                                } else {
                                    bookAppointment();
                                }
                            }
                            if (
                                activeTab == 'time' &&
                                singleBusinessDetails?.policies != ''
                            ) {
                                setShowPolicy(true);
                            }
                        }}
                        data-bs-toggle={activeTab === 'time' && 'modal'}
                        data-bs-target={
                            activeTab === 'time' && '#phoneConfirmationModal'
                        }
                        disabled={!selectedSlots || !selectedDate || isLoading}
                    >
                        {isLoading ? <Spin /> : 'Continue'}
                    </button>
                </div>
            </div>
            {/* modal  */}
            <div
                className="modal fade "
                id="phoneConfirmationModal"
                tabindex="-1"
                aria-labelledby="phoneConfirmationModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content rounded-4 p-4 ">
                        {showPolicy && singleBusinessDetails?.policies != '' ? (
                            <>
                                <div className="modal-header border-0 p-0 pb-2  d-flex justify-content-between ">
                                    <h5
                                        className="modal-title poly f-32"
                                        id="phoneConfirmationModalLabel"
                                    >
                                        Booking Rules & Policy
                                    </h5>
                                </div>
                                <h5 className="poppins f-18 lh-32 c-667085 my-3 fw-normal ">
                                    {singleBusinessDetails?.policies}
                                </h5>

                                <div className="modal-footer border-0">
                                    <button
                                        type="button"
                                        className="f-16 poppins text-white booking-btn w-100 mt-2"
                                        onClick={() => {
                                            setShowPolicy(false);
                                        }}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="modal-header border-0 p-0 pb-2  d-flex justify-content-between ">
                                    <h5
                                        className="modal-title poly f-32"
                                        id="phoneConfirmationModalLabel"
                                    >
                                        Add Phone
                                    </h5>
                                    <svg
                                        role="button"
                                        width="36"
                                        height="36"
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="35"
                                            height="35"
                                            rx="17.5"
                                            stroke="#E1E6EF"
                                        />
                                        <path
                                            d="M22.8538 22.1465C22.9002 22.193 22.9371 22.2481 22.9622 22.3088C22.9874 22.3695 23.0003 22.4346 23.0003 22.5003C23.0003 22.566 22.9874 22.631 22.9622 22.6917C22.9371 22.7524 22.9002 22.8076 22.8538 22.854C22.8073 22.9005 22.7522 22.9373 22.6915 22.9625C22.6308 22.9876 22.5657 23.0006 22.5 23.0006C22.4343 23.0006 22.3693 22.9876 22.3086 22.9625C22.2479 22.9373 22.1927 22.9005 22.1463 22.854L18 18.7072L13.8538 22.854C13.76 22.9478 13.6327 23.0006 13.5 23.0006C13.3674 23.0006 13.2401 22.9478 13.1463 22.854C13.0525 22.7602 12.9998 22.633 12.9998 22.5003C12.9998 22.3676 13.0525 22.2403 13.1463 22.1465L17.2932 18.0003L13.1463 13.854C13.0525 13.7602 12.9998 13.633 12.9998 13.5003C12.9998 13.3676 13.0525 13.2403 13.1463 13.1465C13.2401 13.0527 13.3674 13 13.5 13C13.6327 13 13.76 13.0527 13.8538 13.1465L18 17.2934L22.1463 13.1465C22.2401 13.0527 22.3674 13 22.5 13C22.6327 13 22.76 13.0527 22.8538 13.1465C22.9476 13.2403 23.0003 13.3676 23.0003 13.5003C23.0003 13.633 22.9476 13.7602 22.8538 13.854L18.7069 18.0003L22.8538 22.1465Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>
                                <h5 className="poppins f-18 lh-32 c-667085 mb-4 fw-normal ">
                                    Enter your phone number to confirm your
                                    appointment
                                </h5>
                                <label className="mb-2 poppins f-14 lh-20 c-667085">
                                    Business Location
                                    <span className="text-danger">*</span>
                                </label>
                                <div className="input-group input-filed ">
                                    <span
                                        className="input-group-text border-0 py-0 ps-0 bg-transparent"
                                        id="basic-addon1"
                                    >
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-country-num dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col px-0 c-667085">
                                                            {countryCode}
                                                            <span className="px-2">
                                                                <svg
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M11.6845 5.55977L7.30953 9.93477C7.2689 9.97545 7.22065 10.0077 7.16754 10.0297C7.11443 10.0518 7.05749 10.0631 7 10.0631C6.94251 10.0631 6.88558 10.0518 6.83246 10.0297C6.77935 10.0077 6.7311 9.97545 6.69047 9.93477L2.31547 5.55977C2.23338 5.47768 2.18726 5.36634 2.18726 5.25024C2.18726 5.13415 2.23338 5.0228 2.31547 4.94071C2.39756 4.85862 2.5089 4.8125 2.625 4.8125C2.7411 4.8125 2.85244 4.85862 2.93453 4.94071L7 9.00673L11.0655 4.94071C11.1061 4.90006 11.1544 4.86782 11.2075 4.84582C11.2606 4.82382 11.3175 4.8125 11.375 4.8125C11.4325 4.8125 11.4894 4.82382 11.5425 4.84582C11.5956 4.86782 11.6439 4.90006 11.6845 4.94071C11.7252 4.98136 11.7574 5.02962 11.7794 5.08273C11.8014 5.13584 11.8127 5.19276 11.8127 5.25024C11.8127 5.30773 11.8014 5.36465 11.7794 5.41776C11.7574 5.47087 11.7252 5.51913 11.6845 5.55977Z"
                                                                        fill="#334155"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                            <div className="dropdown-menu country-dropdown-menu c-667085">
                                                <input
                                                    className="country-dropdown-input "
                                                    type="text"
                                                    placeholder="Search for a country..."
                                                    value={searchTerm}
                                                    onChange={(e) =>
                                                        setSearchTerm(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {filteredOptions.map(
                                                    (_country) => (
                                                        <p
                                                            key={_country.label}
                                                            className="dropdown-item cp"
                                                            onClick={() =>
                                                                handleCountryCodeClick(
                                                                    _country
                                                                )
                                                            }
                                                        >
                                                            {_country.flag}{' '}
                                                            {_country.code}
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <svg
                                            width="1"
                                            height="18"
                                            viewBox="0 0 1 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="0.5"
                                                y1="2.18557e-08"
                                                x2="0.499999"
                                                y2="18"
                                                stroke="#E1E6EF"
                                            />
                                        </svg>
                                    </span>

                                    <input
                                        name="phone"
                                        value={phone}
                                        type="text"
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                        className="form-control border-0 py-0 box-shadow-0 c-667085"
                                        placeholder="Enter Your Mobile number"
                                        aria-label="mobileNumber"
                                        aria-describedby="basic-addon1"
                                    />
                                </div>
                                <div className="modal-footer border-0">
                                    <button
                                        type="button"
                                        data-bs-dismiss={phone && 'modal'}
                                        aria-label={phone && 'Close'}
                                        className="f-16 poppins text-white booking-btn w-100 mt-2"
                                        onClick={() => {
                                            if (phone && countryCode) {
                                                setActiveTab('payment');
                                            } else {
                                                toast.error(
                                                    'Please enter your phone number'
                                                );
                                            }
                                        }}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectTimeContinue;
