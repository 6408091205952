import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    handleInsta,
    resetInstaFiles,
} from '../../redux/businessAccount/detailsAction';
import { useEffect } from 'react';

const UploadFromInsta = ({ instaImages }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedImages, setSelectedImages] = useState([]);

    const generateRandomName = () => {
        const timestamp = new Date().getTime();
        const randomString = Math.random().toString(36).substring(2, 8);

        return `${timestamp}_${randomString}.png`;
    };

    const handleImageSelection = async (selectedImage) => {
        const isSelected = selectedImages.some(
            (image) => image.id === selectedImage.id
        );

        if (isSelected) {
            setSelectedImages(
                selectedImages.filter((image) => image.id !== selectedImage.id)
            );
        } else {
            setSelectedImages([...selectedImages, selectedImage]);
        }
    };


    const convertToFiles = async () => {
        try {
            const filePromises = selectedImages.map(async (item) => {
                const imageBlob = await fetch(item.media_url).then((response) =>
                    response.blob()
                );
                const randomName = generateRandomName();
                let imgFile = new File([imageBlob], randomName, {
                    type: 'image/jpeg',
                });
                const previewPic = URL.createObjectURL(imgFile);
                return { file: imgFile, previewPic };
            });

            const imageFiles = await Promise.all(filePromises);
            dispatch(handleInsta(imageFiles));
            history.push('/dashboard/setting');
        } catch (error) {
            console.error('Error converting images to files:', error);
        }
    };

    useEffect(() => {
        if (selectedImages.length === 0) {
            dispatch(resetInstaFiles());
        }
    }, [selectedImages]);

    return (
        <div className={`instagram-modal-dialog`}>
            <div className={`instagram-modal-content`}>
                <h5 className={`c-334155 poly f-36 fw-normal lh-48 mb-3`}>
                    User Images
                </h5>
                <div className={`container`}>
                    <div
                        className={`row align-items-center justify-content-center max-h-500 overflow-auto `}
                    >
                        {instaImages.length > 0 &&
                            instaImages.map((image, index) => (
                                <div
                                    key={image.id}
                                    className={`col-4 ps-0 pb-3`}
                                >
                                    <label
                                        htmlFor={`image_${image.id}`}
                                        className={`position-relative cp`}
                                    >
                                        <img
                                            src={image.media_url}
                                            alt="User Image"
                                            className={`img-fluid br-5`}
                                        />
                                        <input
                                            type="checkbox"
                                            id={`image_${image.id}`}
                                            checked={selectedImages.some(
                                                (selectedImage) =>
                                                    selectedImage.id ===
                                                    image.id
                                            )}
                                            onChange={() =>
                                                handleImageSelection(image)
                                            }
                                            className={`position-absolute top-10 right-10`}
                                        />
                                    </label>
                                </div>
                            ))}
                    </div>
                </div>
                <div className={`col-12 position-relative`}>
                    {selectedImages.length >= 6 && (
                        <p className={`password-error-text left-0`}>
                            You can only select a maximum of 6 images.
                        </p>
                    )}
                </div>
                <button
                    className={`btn btn-next mt-4`}
                    onClick={convertToFiles}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default UploadFromInsta;
