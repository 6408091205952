import { useState } from 'react';
import './appointments.css';
import UpcomingAppointments from './UpcomingAppointments';
import BreadCrum from '../features/breadcrum/BreadCrum';
import AppointmentDetails from './Appointment-details';
import PreviousAppointments from './PreviousAppointments';

const Appointments = () => {
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [upcomingActiveIndex, setUpcomingActiveIndex] = useState(undefined);
    const [previousActiveIndex, setPreviousActiveIndex] = useState(undefined);

    return (
        <div className="mb-5 container p-4 position-relative">
            <BreadCrum
                items={[
                    { title: 'Home', path: '/home' },
                    {
                        title: 'Appointments',
                        path: '/customer/upcoming-appointments',
                    },
                ]}
            />
            <div className="row d-flex justify-space-between">
                <div className="col-lg-4 col-sm-12 mb-3">
                    <UpcomingAppointments
                        activeIndex={upcomingActiveIndex}
                        returnSelectedAppointment={(index, obj) => {
                            setSelectedAppointment(obj);
                            setPreviousActiveIndex(undefined);
                            setUpcomingActiveIndex(index);
                        }}
                    />
                    <PreviousAppointments
                        activeIndex={previousActiveIndex}
                        returnSelectedAppointment={(index, obj) => {
                            setSelectedAppointment(obj);
                            setUpcomingActiveIndex(undefined);
                            setPreviousActiveIndex(index);
                        }}
                    />
                </div>
                <div className="col-lg-8 col-sm-12">
                    {selectedAppointment !== null && (
                        <AppointmentDetails
                            selectedAppointment={selectedAppointment}
                            setSelectedAppointment={setSelectedAppointment}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Appointments;
