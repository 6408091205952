import React, { useEffect, useState } from 'react';
import SVG from './SVG';
import { SERVICES } from '../../helpers/constants';

const Services = ({ handleNext, handlePrev, setFormData, formData }) => {
    const [selectedServices, setSelectedServices] = useState([]);
    const [error, setError] = useState('');

    const onServiceChange = (selectedServices) => {
        setSelectedServices(selectedServices);
        updateFormData(selectedServices);
    };

    const updateFormData = (selectedServices) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            services: selectedServices,
        }));
    };

    const handleServiceChange = (name) => {
        const updatedServices = selectedServices.includes(name)
            ? selectedServices.filter((_name) => _name !== name)
            : [...selectedServices, name];

        setSelectedServices(updatedServices);
        onServiceChange(updatedServices);
    };

    const handleNextClick = () => {
        console.log(formData.services, 'services');
        if (formData.services.length < 1) {
            setError('Select at least 1 service');
        } else {
            handleNext();
        }
    };

    useEffect(() => {
        setSelectedServices(formData?.services);
    }, []);

    return (
        <div className={`container-fluid max-w-md-605`}>
            <div className={`row justify-content-center pb-md-40 pb-3`}>
                <div className={`col-md-12 px-0 text-center pt-4 pb-16`}>
                    <h5 className={`m-0 c-334155 poly f-36 fw-normal lh-48`}>
                        What services do you offer?
                    </h5>
                </div>
                <div className={`col-md-8 col-12 px-0 text-center lh-0`}>
                    <span
                        className={`m-0 c-667085 poppins f-14 fw-normal lh-20`}
                    >
                        choose your primary and up to 3 related service types
                    </span>
                </div>
            </div>
            <div
                className={`row justify-content-center pb-md-40 position-relative`}
            >
                {SERVICES.map((service, index) => (
                    <div
                        key={index}
                        className={`col-md-4 col-6 px-md-9 px-16 pb-18 w-md-150px`}
                    >
                        <label className="checkbox-container">
                            <input
                                className={`step-input`}
                                checked={selectedServices.includes(
                                    service.name
                                )}
                                onChange={() =>
                                    handleServiceChange(service.name)
                                }
                                type="checkbox"
                            />
                            <div
                                className={`container-fluid pt-32 pb-22 checked-color`}
                            >
                                <div
                                    className={`row justify-content-center h-84`}
                                >
                                    <div className={`col-12 px-0 text-center`}>
                                        <SVG icon={service.icon} />
                                    </div>
                                    <div className={`col-12 px-0 w-200`}>
                                        <h6
                                            className={`mb-0 mx-auto checked-text`}
                                        >
                                            {service.name}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <span className={`bg-checkbox`}>
                                <span className="checkmark"></span>
                            </span>
                        </label>
                    </div>
                ))}
                <div className={`col-12 px-md-9 px-16 position-relative`}>
                    {error && (
                        <p className={`password-error-text l-60`}>{error}</p>
                    )}
                </div>
            </div>

            <div className={`row px-48 pt-4 mt-2`}>
                <div
                    className={`col-lg-6 col-6 pr-md-7-5`}
                    onClick={handlePrev}
                >
                    <button className={`btn btn-back`}>Back</button>
                </div>
                <div
                    className={`col-lg-6 col-6 pl-md-7-5 pb-lg-0 pb-3`}
                    onClick={handleNextClick}
                >
                    <button className={`btn btn-next`}>Next</button>
                </div>
            </div>
        </div>
    );
};

export default Services;
