import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBFplVXvV5JabuVSwIiUbmfnkAm0QHH_q4",
  authDomain: "tisume-platform.firebaseapp.com",
  projectId: "tisume-platform",
  storageBucket: "tisume-platform.appspot.com",
  messagingSenderId: "275191161581",
  appId: "1:275191161581:web:25c15ba7111c287e78e2e4",
  measurementId: "G-QES9YG554H"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);