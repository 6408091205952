import React, { useState } from "react";
import ServiceHeader from "./ServiceHeader";
import ServiceBookNow from "./service-book-now";
import { useEffect } from "react";

const Services = ({ businessDetails }) => {
  const [showServices, setShowServices] = useState(5);
  const [category, setCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  const handleShowAll = () => {
    setShowServices(Number(businessDetails?.services?.length));
  };

  const handleCategoryChange = (item) => {
    setCategory(item.category);
    setCategoryData(item.services);
  };

  useEffect(() => {
    const firstCategory =
      businessDetails?.services && Object.entries(businessDetails?.services)[0];
    if (firstCategory) {
      const [category, data] = firstCategory;
      setCategory(category);
      setCategoryData(data.services);
    }
  }, [businessDetails]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-4 mt-3 poly f-36 me-2  ">Services</p>{" "}
        {showServices < categoryData?.length && (
          <p
            className="m-0 c-3A475C fs-16 poppins cp  d-md-none d-block"
            onClick={handleShowAll}
          >
            <u>See More</u>
          </p>
        )}
      </div>
      <ServiceHeader
        services={businessDetails?.services}
        handleCategoryChange={handleCategoryChange}
        category={category}
      />

      {categoryData?.slice(0, showServices)?.map((item, index) => (
        <ServiceBookNow item={item} id={businessDetails?.id} key={index} />
      ))}
      {showServices < categoryData?.length && (
        <p
          className="m-0 c-3A475C fs-16 poppins cp my-2 d-md-block d-none"
          onClick={handleShowAll}
        >
          <u>See More</u>
        </p>
      )}
    </div>
  );
};

export default Services;
