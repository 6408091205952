import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Axios from '../../config/Axios';
import AuthLayout from './auth-layout';
import { isArray } from '../../helpers/functions';
import logo from '../../assets/images/Appointments/Logo-Header.png'

const ResetPassword = () => {
    const history = useHistory();

    const { token } = useParams();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});

    const resetPassword = async () => {
        try {
            setErrors({});
            const response = await Axios.post(`auth/reset-password`, {
                token,
                password,
                confirmPassword,
            });
            console.log(response, 'response');

            history.push('/login?passwordSuccess=true');
        } catch (error) {
            console.log(error, 'Error');
            const updatedFieldErrors = {};
            const data = error?.response?.data;

            if (isArray(data?.message)) {
                data?.message?.forEach((error) => {
                    updatedFieldErrors[error.field] = error.message;
                });
            } else if (data.statusCode === 400) {
                updatedFieldErrors.confirmPassword = data.message;
            } else if (data.statusCode === 404) {
                updatedFieldErrors.confirmPassword = data.message;
            }

            setErrors(updatedFieldErrors);
        }
    };

    return (
        <AuthLayout>
            <Link className="d-flex justify-content-center mb-4" to="/home">
                <img src={logo} alt="Logo" style={{ width: '132px' }} />
            </Link>
            <div className="col-12 px-0 pt-lg-0 pt-5">
                <h5 className="c-334155 poly f-36 fw-400 lh-48 m-0 text-center">
                    Reset your password
                </h5>
            </div>

            <div className="col-12 px-0 pb-md-30 pb-3">
                <h6 className="c-667085 poppins f-14 fw-400 lh-20 m-0 text-center">
                    please fill the information below
                </h6>
            </div>

            <div className="col-12 px-md-0 position-relative">
                <div className="mb-20">
                    <label
                        htmlFor="password"
                        className="form-label c-667085 poppins f-14 fw-400 lh-20"
                    >
                        Password <span className="text-danger">*</span>
                    </label>
                    <input
                        type="password"
                        className="form-control input-filed"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="New Password"
                    />

                    {errors.password && (
                        <p className="password-error-text">{errors.password}</p>
                    )}
                </div>
            </div>

            <div className="col-12 px-md-0 position-relative">
                <div className="mb-20">
                    <label
                        htmlFor="password"
                        className="form-label c-667085 poppins f-14 fw-400 lh-20"
                    >
                        Confirm Password <span className="text-danger">*</span>
                    </label>
                    <input
                        type="password"
                        className="form-control input-filed"
                        id="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                    />

                    {errors.confirmPassword && (
                        <p className="password-error-text">
                            {errors.confirmPassword}
                        </p>
                    )}
                </div>
            </div>

            <div className="col-12 px-md-0">
                <button
                    className="btn btn-create-acc mb-20"
                    onClick={resetPassword}
                >
                    Reset Password
                </button>
            </div>
        </AuthLayout>
    );
};

export default ResetPassword;
