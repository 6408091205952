import React, { useState } from 'react';
import avatar from '../../assets/images/avatar.png';
import Image from '../../assets/images/image.png';
import { useSelector } from 'react-redux';
import EditProfileModal from './EditProfileModal';
import { useDispatch } from 'react-redux';
import {
    setUser,
    updateCustomerPersonalInfo,
} from '../../redux/users/actionCreator';
import { destroySession } from '../../helpers/functions';
import { persistor } from '../../redux/store';
import { handleLogout } from '../../redux/authentication/actionCreator';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Spin } from 'antd';
import { toast } from 'react-toastify';

const PersonalInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { userData, isLoading } = useSelector((state) => state.user);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleProfileImg = (e) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append('profile_photo', file);
        let id = userData?.id;
        let message = 'Your profile picture updated successfully';
        dispatch(updateCustomerPersonalInfo(id, formData, message));
    };

    const SignOut = async () => {
        setUser(null);
        destroySession();
        await persistor.purge();
        dispatch(handleLogout());
        history.push('/login-customer');
    };

    const handleCopyClick = async () => {
        let url = userData
            ? `${window.location.origin}/register-customer?referral=${userData?.id}`
            : window.location.href;
        navigator.clipboard.writeText(url);
        toast.success('Referral link copied');
    };

    return (
        <div className="border border-color-E1E6EF rounded-4  col-12 col-md-3 poppins py-5 ">
            <div className="d-flex flex-column justify-content-center align-items-center  border-bottom ">
                <div className="d-flex ">
                    <div className="position-relative">
                        {isLoading ? (
                            <div className='profile_img d-flex align-items-center justify-content-center'>

                                <Spin />
                            </div>
                        ) : (
                            <>
                                <img
                                    src={userData?.profile_photo_path || avatar}
                                    clear
                                    alt="Avatar"
                                    className="profile_img"
                                />
                                <label
                                    htmlFor="profileImg"
                                    className=" position-absolute bottom-0 end-0"
                                >
                                    <img
                                        src={Image}
                                        alt="camera"
                                        width={40}
                                        role="button"
                                    />
                                </label>
                            </>
                        )}
                    </div>

                    <input
                        id="profileImg"
                        type="file"
                        accept=".jpg , .jpeg ,.png, .svg"
                        className="d-none   "
                        onChange={handleProfileImg}
                    />
                </div>
                <span className="py-3 poppins c-0f151d fw-medium f-18 text-capitalize">
                    {userData?.name}
                </span>

                <div className='d-flex gap-2'>
                    <div className="border-card-pill mb-3">
                        <div className="py-1 px-2 " onClick={toggleModal}>
                            <span
                                className="poppins f-14 lh-20 c-334155s"
                                role="button"
                            >
                                Edit Info
                            </span>
                            <span className="fw-medium ps-2">
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.431 3.01284L8.98758 0.568852C8.90633 0.48758 8.80986 0.42311 8.70369 0.379125C8.59751 0.335139 8.48372 0.3125 8.36879 0.3125C8.25387 0.3125 8.14007 0.335139 8.0339 0.379125C7.92773 0.42311 7.83126 0.48758 7.75 0.568852L1.00649 7.31291C0.924884 7.39387 0.860185 7.49024 0.816154 7.59642C0.772123 7.70261 0.749637 7.81648 0.750004 7.93143V10.3754C0.750004 10.6075 0.842192 10.83 1.00629 10.9941C1.17038 11.1582 1.39294 11.2504 1.625 11.2504H4.06899C4.18394 11.2508 4.29781 11.2283 4.404 11.1843C4.51018 11.1402 4.60655 11.0755 4.6875 10.9939L11.431 4.25041C11.5123 4.16916 11.5768 4.07269 11.6207 3.96652C11.6647 3.86035 11.6874 3.74655 11.6874 3.63163C11.6874 3.5167 11.6647 3.4029 11.6207 3.29673C11.5768 3.19056 11.5123 3.09409 11.431 3.01284ZM4.06899 10.3754H1.625V7.93143L6.4375 3.11893L8.88149 5.56291L4.06899 10.3754ZM9.5 4.94385L7.05602 2.50041L8.36852 1.18791L10.8125 3.63135L9.5 4.94385Z"
                                        fill="black"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="border-card-pill mb-3">
                        <div className="py-1 px-2 " onClick={handleCopyClick}>
                            <span
                                className="poppins f-14 lh-20 c-334155s"
                                role="button"
                            >
                                Referral Link
                            </span>
                            <span className="fw-medium ps-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    width="12"
                                    height="12"
                                >
                                    <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="align-items-center d-flex justify-content-start  px-4 py-3  border-bottom ">
                <div className="w-100">
                    <p className="poppins f-16 lh-20 fw-medium pt-1">
                        Personal information
                    </p>
                    <div>
                        <p className="c-667085  f-14 lh-17 mb-2">Name</p>
                        <p className="poppins f-16 lh-17 fw-medium text-capitalize">
                            {userData.name}
                        </p>
                    </div>
                    <div>
                        <p className="c-667085  f-14 lh-17  mb-2">
                            Mobile number
                        </p>
                        <p className="poppins f-16 lh-17 fw-medium">
                            {userData?.phone.split('-')[0] +
                                userData?.phone.split('-')[1]}
                        </p>
                    </div>
                    <div>
                        <p className="c-667085  f-14 lh-17  mb-2">
                            Email address
                        </p>
                        <p className="poppins f-16 lh-17 fw-medium overflow-hidden ">
                            {userData?.email}
                        </p>
                    </div>
                    <div>
                        <p className="c-667085  f-14 lh-17  mb-2">
                            Date of birth
                        </p>
                        <p className="poppins f-16 lh-20 fw-medium">
                            {userData?.date_of_birth
                                ? moment(userData.date_of_birth).format(
                                    'DD/MM/YYYY'
                                )
                                : 'N/A'}
                        </p>
                    </div>

                    <div>
                        <p className="c-667085  f-14 lh-17  mb-2">Gender</p>
                        <p className="poppins f-16 lh-17 fw-medium text-capitalize">
                            {userData?.gender || 'N/A'}
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="d-flex justify-content-center align-items-center pt-5"
                role="button"
            >
                <div className="gap-2 d-flex justify-content-center align-items-center">
                    <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.25 16.875C9.25 17.0408 9.18415 17.1997 9.06694 17.3169C8.94973 17.4342 8.79076 17.5 8.625 17.5H4.25C3.91848 17.5 3.60054 17.3683 3.36612 17.1339C3.1317 16.8995 3 16.5815 3 16.25V3.75C3 3.41848 3.1317 3.10054 3.36612 2.86612C3.60054 2.6317 3.91848 2.5 4.25 2.5H8.625C8.79076 2.5 8.94973 2.56585 9.06694 2.68306C9.18415 2.80027 9.25 2.95924 9.25 3.125C9.25 3.29076 9.18415 3.44973 9.06694 3.56694C8.94973 3.68415 8.79076 3.75 8.625 3.75H4.25V16.25H8.625C8.79076 16.25 8.94973 16.3158 9.06694 16.4331C9.18415 16.5503 9.25 16.7092 9.25 16.875ZM17.8172 9.55781L14.6922 6.43281C14.5749 6.31554 14.4159 6.24965 14.25 6.24965C14.0841 6.24965 13.9251 6.31554 13.8078 6.43281C13.6905 6.55009 13.6247 6.70915 13.6247 6.875C13.6247 7.04085 13.6905 7.19991 13.8078 7.31719L15.8664 9.375H8.625C8.45924 9.375 8.30027 9.44085 8.18306 9.55806C8.06585 9.67527 8 9.83424 8 10C8 10.1658 8.06585 10.3247 8.18306 10.4419C8.30027 10.5592 8.45924 10.625 8.625 10.625H15.8664L13.8078 12.6828C13.6905 12.8001 13.6247 12.9591 13.6247 13.125C13.6247 13.2909 13.6905 13.4499 13.8078 13.5672C13.9251 13.6845 14.0841 13.7503 14.25 13.7503C14.4159 13.7503 14.5749 13.6845 14.6922 13.5672L17.8172 10.4422C17.8753 10.3841 17.9214 10.3152 17.9528 10.2393C17.9843 10.1635 18.0005 10.0821 18.0005 10C18.0005 9.91787 17.9843 9.83654 17.9528 9.76066C17.9214 9.68479 17.8753 9.61586 17.8172 9.55781Z"
                            fill="#EB5757"
                        />
                    </svg>
                    <span
                        className="text-danger  f-20"
                        onClick={SignOut}
                    >
                        Log out
                    </span>
                </div>
            </div>
            <EditProfileModal
                isModalVisible={isModalVisible}
                handleClose={toggleModal}
            />
        </div>
    );
};

export default PersonalInfo;
