import React, { useState } from 'react';
import './create-account.css';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { toast } from 'react-toastify';
import { SignIn } from '../../redux/authentication/actionCreator';
import { useSelector } from 'react-redux';
import { validateEmail, validatePassword } from '../../helpers/constants';
import AuthLayout from '../../container/auth/auth-layout';
import logo from '../../assets/images/Appointments/Logo-Header.png';

const LoginForEveryone = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading } = useSelector((state) => state.auth);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const handleCredentials = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            setEmail(value);
            if (validateEmail(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        } else if (name === 'password') {
            setPassword(value);
            if (validatePassword(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        }
    };

    const CustomerSignIn = async (e) => {
        e.preventDefault();
        setErrors({});
        const [isEmailValid, isPasswordValid] = await Promise.all([
            validateEmail(email, setErrors),
            validatePassword(password, setErrors),
        ]);
        if (isEmailValid && isPasswordValid) {
            dispatch(
                SignIn({ email, password }, history, () => {
                    toast.success('Logged in successfully');
                })
            );
        }
    };

    return (
        <AuthLayout>
            <Link className="d-flex justify-content-center mb-4" to="/home">
                <img src={logo} alt="Logo" style={{ width: '132px' }} />
            </Link>
            <div className={`col-12 px-0 `}>
                <h5
                    className={`c-334155 poly f-36 fw-400 lh-48 m-0 text-center`}
                >
                    Tisume for customers
                </h5>
            </div>
            <div className={`col-12 px-0 pb-md-30 pb-3`}>
                <h6
                    className={`c-667085 poppins f-14 fw-400 lh-20 m-0 text-center`}
                >
                    Log in to book an appointment
                </h6>
            </div>
            <form onSubmit={CustomerSignIn}>
                <div className={`col-12 px-md-0 position-relative`}>
                    <div className="mb-20">
                        <label
                            htmlFor="email"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Email Address{' '}
                            <span className={`text-danger`}>*</span>
                        </label>
                        <input
                            type="email"
                            className="form-control input-filed"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleCredentials}
                            placeholder="Your Email"
                        />
                        {errors.email && (
                            <p className="text-danger fs-12">{errors.email}</p>
                        )}
                    </div>
                </div>
                <div className={`col-12 px-md-0 position-relative`}>
                    <div className="mb-20">
                        <label
                            htmlFor="password"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Password <span className={`text-danger`}>*</span>
                        </label>
                        <input
                            type="password"
                            className="form-control input-filed"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleCredentials}
                            placeholder="Your Password"
                        />
                        {errors.password && (
                            <p className="text-danger fs-12">
                                {errors.password}
                            </p>
                        )}
                    </div>
                </div>

                <div className={`col-12 px-md-0 mt-2`}>
                    <button
                        className={`btn btn-create-acc mb-20`}
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <Spin /> : 'Sign In'}
                    </button>
                    <Link
                        to={'/register-customer'}
                        style={{ paddingTop: '11px', paddingBottom: '11px' }}
                        className={`btn btn-outline mb-20 border rounded-pill w-100`}
                        disabled={loading}
                    >
                        Sign up
                    </Link>
                </div>
            </form>

            <div className={`col-12 px-md-0 pb-lg-0 pb-5`}>
                <h6 className="m-0 c-667085 poppins f-14 f-mobile-16 fw-normal lh-20 text-center mb-3">
                    <Link
                        to="/login"
                        className="c-334155 poppins f-14 f-mobile-16 lh-20 ms-2"
                    >
                        Sign up/Sign In as a business{' '}
                    </Link>
                </h6>
                <h6
                    className={`m-0 c-667085 poppins f-14 f-mobile-16 fw-normal lh-20 text-center`}
                >
                    <Link
                        to="/forget-password"
                        className={`text-decoration-none c-334155 poppins f-14 f-mobile-16 fw-medium lh-20 ms-2`}
                    >
                        Forget Password?
                    </Link>
                </h6>
            </div>
        </AuthLayout>
    );
};

export default LoginForEveryone;
