import React from 'react';
import { Modal } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import StripeCard from './StripeCard';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_Stripe_Key);

const AddPaymentModal = ({ isModalVisible, handleClose }) => {
    return (
        <Modal visible={isModalVisible} onCancel={handleClose} footer={null}>
            <h1 className="poppins fw-600 f-18 lh-24 c-0f151d">
                Add your payment method
            </h1>
            <Elements stripe={stripePromise}>
                <StripeCard handleClose={handleClose} />
            </Elements>
        </Modal>
    );
};

export default AddPaymentModal;
