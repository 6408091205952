import React, { useEffect, useState } from 'react';
import './appointments.css';
import { useDispatch } from 'react-redux';
import { getCustomerAppointments } from '../redux/appointments/appointmentAction';
import { useSelector } from 'react-redux';

import AppointmentCard from '../components/cards/appointmentCard/appointmentCard';

const UpcomingAppointments = ({ activeIndex, returnSelectedAppointment }) => {
    const { isLoading, cutomerAppointments } = useSelector(
        (state) => state.appointments
    );

    const dispatch = useDispatch();
    const handleCardClick = (index, appointment) => {
        returnSelectedAppointment(index, appointment);
    };

    useEffect(() => {
        dispatch(getCustomerAppointments());
    }, []);

    return (
        <AppointmentCard
            title="Upcoming Appointments"
            length={
                cutomerAppointments?.length > 0
                    ? cutomerAppointments.length
                    : 'no '
            }
            appointments={cutomerAppointments}
            handleCardClick={handleCardClick}
            activeIndex={activeIndex}
        />
    );
};

export default UpcomingAppointments;
