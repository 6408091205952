import React from "react";
import "./services.css";
import Checked from "../../assets/images/services/checked.svg";

const SingleService = ({selectedServices , handleSelectedServices, item }) => {

  return (
    <div>
      <div className="single-service-card w-100 mt-3">
        <div className="row">
          <div className="col-10">
            <div className="d-flex align-items-center">
              <p className="m-0 fw-medium c-0f151d poppins f-18">
                {item?.name}
              </p>
              <div className="service-dot mx-2"></div>
              <p className="m-0 c-667085 f-16 poppins">{item?.duration}</p>
            </div>
            <p className="m-0 c-667085 f-16 poppins fw-light mb-2">
              {item?.description}
            </p>
            <p className="m-0 fw-medium  poppins f-16 c-101828">
              £{item?.price}
            </p>
          </div>
          <div className=" col-2  mt-md-0 mt-2 d-flex justify-content-md-end justify-content-start align-items-center">
            <div
              className={`custom-checkbox cp ${
                selectedServices?.find((ele)=>ele.id == item.id) ? "custom-checkbox-active" : ""
              }`}
              onClick={()=>handleSelectedServices(item)}
            >
              {selectedServices?.find((ele)=>ele.id == item.id) && <img src={Checked} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleService;
