import React, { Fragment } from 'react';
import logo from '../assets/images/transparent-logo.svg';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../helpers/functions';
import { useSelector } from 'react-redux';

const Header = () => {
    window.addEventListener('resize', (event) => {
        document
            .getElementById('offcanvasDarkNavbar')
            ?.classList.remove('show');

        let elements = document.querySelectorAll('.offcanvas-backdrop');
        elements.forEach(function (element) {
            // Do something with each element
            element.classList.remove('show');
        });
    });
    const { userData } = useSelector((state) => state.user);

    return (
        <nav className="navbar navbar-expand-lg p-0">
            <div className="container-fluid px-md-120 px-5 py-4 py-lg-0 lh-0">
                <div className='w-100 d-flex justify-content-between'>
                    <Link className="py-md-29 me-md-5 me-3" to="/home">
                        <img src={logo} style={{ width: '146px' }} alt="Logo" />
                    </Link>
                    <button
                        className="navbar-toggler border-light d-sm-none d-block"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar"
                        aria-controls="offcanvasDarkNavbar"
                        aria-label="Toggle navigation"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(173, 180, 210)" className="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 4.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                    <div
                        className="offcanvas offcanvas-end text-bg-dark d-sm-none d-block"
                        tabIndex="-1"
                        id="offcanvasDarkNavbar"
                        aria-labelledby="offcanvasDarkNavbarLabel"
                    >
                        <div className="offcanvas-header justify-content-between pt-49px px-16">
                            <img src={logo} alt="Logo" />

                            <button
                                type="button"
                                className="btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                                        fill="white"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="offcanvas-body px-16">
                            {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item py-2">
                                <Link
                                    className="nav-link py-0 ps-0 pr-md-32 active"
                                    aria-current="page"
                                    to="/home"
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item dropdown py-2">
                                <a
                                    className="nav-link dropdown-toggle py-0 ps-0 pr-md-32"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Services
                                    <svg
                                        className={`ml-md-8`}
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.19922 7.5L10.1992 12.5L15.1992 7.5"
                                            stroke="white"
                                            strokeWidth="1.67"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                                <ul className="dropdown-menu d-none">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Action
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Another action
                                        </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Something else here
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown py-2">
                                <a
                                    className="nav-link dropdown-toggle py-0 ps-0 pr-md-32"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Resources
                                    <svg
                                        className={`ml-md-8`}
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.19922 7.5L10.1992 12.5L15.1992 7.5"
                                            stroke="white"
                                            strokeWidth="1.67"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                                <ul className="dropdown-menu d-none">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Action
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Another action
                                        </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Something else here
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item py-2">
                                <a
                                    className="nav-link py-0 ps-0 pr-md-32"
                                    href="#"
                                >
                                    For Business
                                </a>
                            </li>
                        </ul> */}
                            {isAuthenticated() ? (
                                <Link
                                    className="btn btn-outline-light rounded-pill f-16 fw-normal poppins py-md-11 px-md-20 my-2"
                                    to={'/dashboard'}
                                >
                                    {userData?.role === 'customer'
                                        ? 'Profile'
                                        : 'Dashboard'}
                                </Link>
                            ) : (
                                <div className="d-flex flex-column gap-3">
                                    <Link
                                        className="text-white text-decoration-underline nav-link py-0 ps-0 pr-md-32"
                                        to={'/register-customer'}
                                    >
                                        Sign up
                                    </Link>
                                    <Link
                                        className="text-white text-decoration-underline nav-link py-0 ps-0 pr-md-32"
                                        to={'/login-customer'}
                                    >
                                        Login
                                    </Link>
                                    <div className="py-2">
                                        <Link
                                            className="btn btn-light rounded-pill fs-6 fw-normal poppins py-md-11 px-md-20"
                                            to={'/register'}
                                        >
                                            Create Business Account
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className="collapse navbar-collapse d-sm-flex justify-content-end d-none"
                        id="navbarSupportedContent"
                    >
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a
                                className="nav-link py-0 ps-0 pr-md-32 active"
                                aria-current="page"
                                href="#"
                            >
                                Home
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle py-0 ps-0 pr-md-32"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Services
                                <svg
                                    className={`ml-md-8`}
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.19922 7.5L10.1992 12.5L15.1992 7.5"
                                        stroke="white"
                                        strokeWidth="1.67"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>
                            <ul className="dropdown-menu d-none">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Action
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Another action
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Something else here
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle py-0 ps-0 pr-md-32"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Resources
                                <svg
                                    className={`ml-md-8`}
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.19922 7.5L10.1992 12.5L15.1992 7.5"
                                        stroke="white"
                                        strokeWidth="1.67"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>
                            <ul className="dropdown-menu d-none">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Action
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Another action
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Something else here
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link py-0 ps-0 pr-md-32" href="#">
                                For Business
                            </a>
                        </li>
                    </ul> */}
                        {isAuthenticated() ? (
                            <Link
                                className="btn btn-outline-light rounded-pill f-16 fw-normal poppins py-md-11 px-md-20 my-2 ms-auto"
                                to={'/dashboard'}
                            >
                                {userData?.role === 'customer'
                                    ? 'Profile'
                                    : 'Dashboard'}
                            </Link>
                        ) : (
                            <div className="d-flex gap-3">
                                <Link
                                    className="btn btn-outline-light rounded-pill border-0 text-decoration-underline f-16 fw-normal poppins py-md-11 px-md-20 my-2"
                                    to={'/register-customer'}
                                >
                                    Sign up
                                </Link>
                                <Link
                                    className="btn btn-outline-light rounded-pill border-0 text-decoration-underline f-16 fw-normal poppins py-md-11 px-md-20 my-2"
                                    to={'/login-customer'}
                                >
                                    Login
                                </Link>

                                <Link
                                    className="btn btn-light rounded-pill f-16 fw-normal poppins py-md-11 px-md-20 my-2"
                                    to={'/register'}
                                >
                                    Create Business Account
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
