export const BOOK_APPOINTMENT_START = 'BOOK_APPOINTMENT_START';
export const BOOK_APPOINTMENT_SUCCESS = 'BOOK_APPOINTMENT_SUCCESS';
export const BOOK_APPOINTMENT_ERROR = 'BOOK_APPOINTMENT_ERROR';

export const GET_BUSINESS_APP_START = 'GET_BUSINESS_APP_START';
export const GET_BUSINESS_APP_SUCCESS = 'GET_BUSINESS_APP_SUCCESS';
export const GET_BUSINESS_APP_ERROR = 'GET_BUSINESS_APP_ERROR';

export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const PENDING_APPOINTMENTS = 'PENDING_APPOINTMENTS';
export const REJECTED_APPOINTMENTS = 'REJECTED_APPOINTMENTS';
export const CANCEL_APPOINTMENT_LOADING = 'CANCEL_APPOINTMENT_LOADING';

export const PREVIOUS_APP_PENDING = 'PREVIOUS_APP_PENDING';
export const PREVIOUS_APP_SUCCESS = 'PREVIOUS_APP_SUCCESS';
export const PREVIOUS_APP_ERROR = 'PREVIOUS_APP_ERROR';

export const RESCHEDULE_APPOINTMENT_LOADING = 'RESCHEDULE_APPOINTMENT_LOADING';
