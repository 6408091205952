import React from 'react';
import BusinessCards from '../../components/BusinessCards';

const CheckoutNewPlaces = ({ data }) => {
    return (
        <div className={`container pb-md-0 pb-3`}>
            <div className={`row py-md-100 py-5`}>
                <div className={`col-auto pb-md-40 pb-3 px-16`}>
                    <h1
                        className={`poly f-36 fw-normal lh-48 c-334155 mb-16 text-md-start text-center`}
                    >
                        Check Out New Places
                    </h1>
                    <h3
                        className={`poppins f-18 fw-normal lh-32 c-667085 text-md-start text-center`}
                    >
                        New places have just been added. Don’t miss trying new
                        experience!
                    </h3>
                </div>

                <div className={`col-12 px-md-0`}>
                    <div className={`container-fluid`}>
                        <div className={`row h-100`}>
                            {data.map((item, index) => {
                                console.log('item: ', item);
                                return (
                                    <div key={index} className='col-12 col-lg-4 px-0'>
                                        <BusinessCards item={item} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutNewPlaces;
