import { REVIEWS_PENDING, GET_REVIEWS, REVIEWS_REJECTED, POST_REVIEWS, GET_RECENT_REVIEWS } from './types';

const initialState = {
    recentReviews: null,
    allReviews: [],
    isLoading: false,
};

const reviewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REVIEWS_PENDING:
            return {
                ...state,
                isLoading: true,
            };

        case GET_REVIEWS:
            return {
                ...state,
                isLoading: false,
                allReviews: action.payload,
            };

            case GET_RECENT_REVIEWS:
                return {
                    ...state,
                    isLoading: false,
                    recentReviews: action.payload,
                };

        case POST_REVIEWS:
            return {
                ...state,
                isLoading: false,
            };

        case REVIEWS_REJECTED:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default reviewsReducer;
