import { Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { alphabetsRegex, numericRegex, options } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateCustomerPersonalInfo } from '../../redux/users/actionCreator';
import { toast } from 'react-toastify';

const EditProfileModal = ({ isModalVisible, handleClose }) => {
    const dispatch = useDispatch();
    const { userData, isLoading } = useSelector((state) => state.user);

    let initialCode = userData?.phone.split('-')[0] || '+44';
    const [countryCode, setCountryCode] = useState(initialCode);
    const [searchTerm, setSearchTerm] = useState('');

    const initialValues = {
        name: userData?.name || '',
        phone: userData?.phone.split('-')[1] || '',
        date_of_birth: userData?.date_of_birth || '',
        gender: userData?.gender || '',
    };

    const [formData, setFormData] = useState(initialValues);

    const filteredOptions = options.filter((country) =>
        country.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCountryCodeClick = (_country) => {
        setCountryCode(_country.code);
        setSearchTerm('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            formData.name &&
            formData.phone &&
            formData.date_of_birth &&
            formData.gender
        ) {
            const data = new FormData();
            data.append('name', formData.name);
            data.append('phone', `${countryCode}-${formData.phone}`);
            data.append('date_of_birth', formData.date_of_birth);
            data.append('gender', formData.gender);

            let id = userData?.id;
            let message =
                'Your personal information has been updated successfully';
            dispatch(
                updateCustomerPersonalInfo(id, data, message, () => {
                    handleClose();
                })
            );
        } else {
            toast.error('All fields are mendatory');
        }
    };

    const getMinDate = () => {
        const today = new Date();
        return new Date(today.setFullYear(today.getFullYear() - 18))
            .toISOString()
            .split('T')[0];
    };

    return (
        <Modal
            title="Update Your Personal Information"
            visible={isModalVisible}
            onCancel={handleClose}
            footer={null}
        >
            <form onSubmit={handleSubmit}>
                <div className="col-12 px-md-0 position-relative">
                    <div className="mb-20">
                        <label
                            htmlFor="name"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Name <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            className="form-control input-filed"
                            id="name"
                            required
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={(e) => {
                                if (alphabetsRegex.test(e.target.value)) {
                                    handleInputChange(e);
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="col-12 px-md-0 position-relative">
                    <label
                        htmlFor="phone"
                        className="form-label c-667085 poppins f-14 fw-400 lh-20"
                    >
                        Mobile number <span className="text-danger">*</span>
                    </label>
                    <div className="input-group input-filed mb-20">
                        <span
                            className="input-group-text border-0 py-0 ps-0 bg-transparent"
                            id="basic-addon1"
                        >
                            <div className="dropdown">
                                <button
                                    className="btn btn-country-num dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col px-0">
                                                {countryCode}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                                <div className="dropdown-menu country-dropdown-menu">
                                    <input
                                        className="country-dropdown-input"
                                        type="text"
                                        placeholder="Search for a country..."
                                        value={searchTerm}
                                        onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                        }
                                    />
                                    {filteredOptions.map((_country) => (
                                        <p
                                            key={_country.label}
                                            className="dropdown-item cp"
                                            onClick={() =>
                                                handleCountryCodeClick(_country)
                                            }
                                        >
                                            {_country.flag} {_country.label}{' '}
                                            {_country.code}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </span>
                        <input
                            name="phone"
                            type="text"
                            className="form-control border-0 py-0 box-shadow-0"
                            required
                            placeholder="Your Number"
                            aria-label="mobileNumber"
                            aria-describedby="basic-addon1"
                            value={formData.phone}
                            onChange={(e) => {
                                if (numericRegex.test(e.target.value)) {
                                    handleInputChange(e);
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="col-12 px-md-0 position-relative">
                    <div className="mb-20">
                        <label
                            htmlFor="date_of_birth"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Date of birth <span className="text-danger">*</span>
                        </label>
                        <input
                            type="date"
                            className="form-control input-filed"
                            id="date_of_birth"
                            required
                            name="date_of_birth"
                            placeholder="Your Date of Birth"
                            max={getMinDate()}
                            value={formData.date_of_birth}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className="col-12 px-md-0 position-relative">
                    <div className="mb-20">
                        <label
                            htmlFor="gender"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Gender <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex gap-3">
                            <div className="d-flex align-items-center gap-1">
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    required
                                    value="male"
                                    checked={formData.gender === 'male'}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="male" className="me-3">
                                    Male
                                </label>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    required
                                    value="female"
                                    checked={formData.gender === 'female'}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="female" className="me-3">
                                    Female
                                </label>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                                <input
                                    type="radio"
                                    id="other"
                                    name="gender"
                                    required
                                    value="other"
                                    checked={formData.gender === 'other'}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="other">Other</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex gap-3 justify-content-end">
                    <button
                        type="button"
                        disabled={isLoading}
                        className="btn btn-outline"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn-success"
                    >
                        {isLoading ? <Spin /> : 'Submit'}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default EditProfileModal;
