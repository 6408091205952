import React from 'react';
import { useHistory } from 'react-router-dom';
import { onSelectedServicesChange } from '../../redux/services/servicesActions';
import { useDispatch } from 'react-redux';
import { isAuthenticated } from '../../helpers/functions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ServiceBookNow = ({ item, id }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { userData } = useSelector((state) => state.user);

    return (
        <div className="service-book-now-card w-100 mt-3">
            <div className="row">
                <div className="col-md-8 cols-12">
                    <div className="d-flex align-items-center">
                        <p className="m-0 fw-medium c-0f151d poppins f-18">
                            {item?.name}
                        </p>
                        <div className="service-dot mx-1"></div>{' '}
                        <p className="m-0 c-667085 f-16 poppins">
                            {item?.duration}
                        </p>
                    </div>
                    <p className="m-0 fw-medium  poppins f-16 c-101828">
                        £{item?.price}
                    </p>
                </div>
                <div className=" col-md-4 cols-12 mt-md-0 mt-2 d-flex justify-content-md-end justify-content-start align-items-center">
                    <button
                        type="button"
                        className="fs-16 poppins c-667085 book-now-transparent text-decoration-none"
                        onClick={() => {
                            if (
                                isAuthenticated() &&
                                userData?.role === 'business'
                            ) {
                                toast.error(
                                    'To book an appointment, you need to create a customer account first.'
                                );
                            } else if (
                                isAuthenticated() &&
                                userData?.role === 'customer' &&
                                userData?.status === 'inactive'
                            ) {
                                toast.error(
                                    'Your account is temporarily deactivated. Please activate your account from the profile settings before booking an appointment.'
                                );
                            } else {
                                dispatch(onSelectedServicesChange([item]));
                                if (
                                    isAuthenticated() &&
                                    userData?.role === 'customer'
                                ) {
                                    history.push(
                                        `/business-details/${id}/services/book-appointment`
                                    );
                                } else {
                                    history.push(`/login-customer`, {
                                        fallback: `/business-details/${id}/services/book-appointment`,
                                    });
                                }
                            }
                        }}
                    >
                        Book Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ServiceBookNow;
