import React, { useEffect, useState } from 'react';
import Watch from '../../assets/images/services/watch.svg';
import Checked from '../../assets/images/services/checked.svg';
import ArrowBack from '../../assets/images/services/Arrow Back.svg';
import ArrowForward from '../../assets/images/services/Arrow Forward.svg';
import './services.css';
import { useSelector } from 'react-redux';
import {
    categorizeSlots,
    convertTo12HourFormat,
    isBoolean,
    shortToFullDayMap,
} from '../../helpers/constants';
import { toast } from 'react-toastify';
import moment from 'moment';

const SelectTime = ({
    selectedSlots,
    selectedDate,
    setSelectedDate,
    setSelectedSlots,
}) => {
    const { singleBusinessDetails } = useSelector((state) => state.services);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [availableSlots, setAvailableSlots] = useState(null);

    const handleSlotClick = (item) => {
        if (selectedSlots && selectedSlots === item) {
            setSelectedSlots(null);
        } else {
            let startTime = item.split('-')[0];
            let selected = `${selectedDate.date}-${selectedDate.month}-${selectedDate.year}`;
            let date = moment(selected).format('DD-MM-YYYY');

            const currentTime = new Date();
            const todayDate = moment().format('DD-MM-YYYY');
            // Parse start time
            const [startHour, startMinute] = startTime.split(':').map(Number);

            // Create a Date object for the start time
            const startTimeDate = new Date(currentTime);
            startTimeDate.setHours(startHour, startMinute, 0, 0);

            const timeDifferenceInHours =
                (startTimeDate - currentTime) / (1000 * 60 * 60);

            if (date !== todayDate || timeDifferenceInHours >= 5) {
                setSelectedSlots(item);
            } else {
                toast.error(
                    'You can only select a slot that starts at least 5 hours after the current time.'
                );
            }
        }

        if (window.innerWidth <= 767) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    const handleDateClick = (item) => {
        if (item) {
            setSelectedDate(item);

            let fullDay = shortToFullDayMap[item?.day];

            if (
                isBoolean(
                    singleBusinessDetails?.openingTimes[fullDay]?.isOpened
                )
            ) {
                let slotsArray =
                    singleBusinessDetails?.openingTimes[fullDay].slots;

                let filteredSlots = slotsArray;

                const currentDate = moment();
                const selectedDate = moment(
                    `${item.year}-${item.month}-${item.date}`,
                    'YYYY-MMMM-D'
                );

                if (selectedDate.isSame(currentDate, 'day')) {
                    // Filter the slots to only include those that are 5 hours greater than the current time
                    filteredSlots = slotsArray.filter((slot) => {
                        // Get the slot start time in 24-hour format
                        const slotTime = moment(slot.split('-')[0], 'HH:mm');

                        // Check if the slot time is at least 5 hours greater than the current time
                        return slotTime.isAfter(
                            moment(currentDate).add(5, 'hours')
                        );
                    });
                }

                let sortedSlots =
                    filteredSlots.length > 0
                        ? categorizeSlots(filteredSlots)
                        : null;
                setAvailableSlots(sortedSlots);
                setSelectedSlots(null);
            }
        }
    };

    const generateDatesAndDays = (startDate) => {
        let advanceBooking = singleBusinessDetails?.bookingPeriod || 0;
        const datesAndDays = [];
        for (let i = advanceBooking; i < advanceBooking + 7; i++) {
            const date = new Date(
                startDate.getTime() + i * 24 * 60 * 60 * 1000
            );
            const day = date
                .toLocaleString('en-US', { weekday: 'long' })
                .slice(0, 3);
            const dayFull = shortToFullDayMap[day];
            const month = date.toLocaleString('en-US', { month: 'long' });
            const year = date.getFullYear();
            const formattedDate = date.getDate();

            // Check if the day is opened
            const isOpened =
               singleBusinessDetails?.openingTimes && singleBusinessDetails.openingTimes[dayFull] &&
                isBoolean(singleBusinessDetails.openingTimes[dayFull].isOpened);
            if (isOpened) {
                const isSelected =
                    selectedDate?.date === formattedDate &&
                    selectedDate?.month === month &&
                    selectedDate?.year === year;

                // Check if date is within blocked range
                const blockType = singleBusinessDetails?.blockType;
                const startingBlockDate = new Date(
                    singleBusinessDetails?.startingBlockDate
                );

                const endingBlockDate = new Date(
                    singleBusinessDetails?.endingBlockDate
                );
                const isBlocked =
                    blockType === 'range' &&
                    date >= startingBlockDate &&
                    date <= endingBlockDate;
                if (
                    !isBlocked
                    // && date.getDate() !== endingBlockDate.getDate()
                ) {
                    datesAndDays.push({
                        month,
                        year,
                        date: formattedDate,
                        day,
                        isSelected,
                    });
                }
            }
        }

        return datesAndDays;
    };

    const handleBackwardClick = () => {
        const newDate = new Date(
            currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        );
        const today = new Date();
        //compare only dates
        today.setHours(0, 0, 0, 0);
        if (newDate >= today) {
            setCurrentDate(newDate);
        }
    };

    const handleForwardClick = () => {
        const newDate = new Date(
            currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
        );
        setCurrentDate(newDate);
    };

    const checkAndAdvanceWeek = () => {
        const datesAndDays = generateDatesAndDays(currentDate);
        if (singleBusinessDetails?.openingTimes && datesAndDays.length === 0) {
            handleForwardClick();
        }
        let item = { ...datesAndDays[0], isSelected: true };
        let fullDay = shortToFullDayMap[item?.day];
        if (singleBusinessDetails?.openingTimes && isBoolean(singleBusinessDetails?.openingTimes[fullDay]?.isOpened)) {
            let slotsArray = singleBusinessDetails?.openingTimes[fullDay].slots;
            let sortedSlots = categorizeSlots(slotsArray);
            setAvailableSlots(sortedSlots);
            setSelectedSlots(null);
        }
    };

    // useEffect(() => {
    //     handleDateClick(selectedDate);
    // }, [selectedDate]);
    const checkIfAnyDayIsOpened = (openingTimes) => {
        for (const day in openingTimes) {
            if (isBoolean(openingTimes[day].isOpened)) {
                return true;
            }
        }
        return false;
    };
    const isBusinessOpened = checkIfAnyDayIsOpened(
        singleBusinessDetails?.openingTimes
    );

    useEffect(() => {
        checkAndAdvanceWeek();
    }, [currentDate]);

    useEffect(() => {
        checkAndAdvanceWeek();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <p className="mb-4 poly f-36 me-2">Select Time</p>
            </div>
            {singleBusinessDetails?.openingTimes &&
            isBusinessOpened &&
            singleBusinessDetails?.blockType !== 'indefinite' ? (
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="slot-time">
                            <span className="fw-600 poppins fs-18 mx-2">
                                {currentDate.toLocaleString('en-US', {
                                    month: 'long',
                                })}{' '}
                                {currentDate.getFullYear()}
                            </span>
                        </div>
                        <div className="poppins fs-16 c--667085 me-4">
                            <img
                                role="button"
                                src={ArrowBack}
                                alt="ArrowBack"
                                className="me-2"
                                onClick={handleBackwardClick}
                            />
                            <img
                                role="button"
                                src={ArrowForward}
                                alt="ArrowForward"
                                onClick={handleForwardClick}
                            />
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-wrap gap-3">
                        <div className="date-container">
                            {generateDatesAndDays(currentDate).map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        role="button"
                                        className={`date ${
                                            item.isSelected
                                                ? 'selected-date'
                                                : ''
                                        }`}
                                        onClick={() => handleDateClick(item)}
                                    >
                                        <div
                                            className={`poppins fs-16 ${
                                                item.isSelected
                                                    ? 'text-white'
                                                    : 'c-667085'
                                            }`}
                                        >
                                            {item.day}
                                        </div>
                                        <div
                                            className={`poppins fs-16 fw-medium  ${
                                                item.isSelected
                                                    ? 'text-white'
                                                    : 'c-0f151d'
                                            }`}
                                        >
                                            {item.date}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column gap-3">
                    <h4>Notice: Bookings Unavailable</h4>
                    <p>
                        We regret to inform you that we are currently not
                        accepting new bookings. We apologize for any
                        inconvenience and appreciate your understanding.
                    </p>
                    <p>Thank you for your patience and support.</p>
                </div>
            )}
            <div className="mt-4 me-3 mb-4 c-e1e6ef border-top hr"></div>
            {selectedDate &&
                availableSlots &&
                Object.entries(availableSlots).map(([key, value], index) => (
                    <div className="" key={index}>
                        <div className="d-flex justify-content-between mt-4">
                            <div className="d-flex align-items-center slot-time">
                                <img src={Watch} alt="Watch" />
                                <span className="fw-600 poppins fs-18 mx-2">
                                    {key}
                                </span>
                            </div>
                            <div className="poppins fs-16 c-667085 me-3">
                                {value?.range}
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-start px-3 mt-3 gap-sm-4 gap-2">
                            {value.slots.map((slot, index) => (
                                <div
                                    className="select-time px-2 py-3 d-flex align-items-center justify-content-center gap-2"
                                    key={index}
                                >
                                    <span className="poppins f-13 c-667085 white-space-no-wrap">
                                        {convertTo12HourFormat(
                                            slot.split('-')[0]
                                        )}
                                        {/* <br />
                    {convertTo12HourFormat(slot.split("-")[1])} */}
                                    </span>
                                    <div
                                        className={`custom-check cp ${
                                            selectedSlots === slot
                                                ? 'custom-checkbox-active'
                                                : ''
                                        }`}
                                        onClick={() => handleSlotClick(slot)}
                                    >
                                        {selectedSlots === slot && (
                                            <img src={Checked} alt="Checked" />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            {selectedDate && !availableSlots && (
                <div>There is no slot available for this date</div>
            )}
        </div>
    );
};

export default SelectTime;
