import moment from "moment";
import avatar from "../../assets/images/avatar.png";
import { useSelector } from "react-redux";

const SingleChat = ({ item, index, selectedChat, handleCardClick, toggle }) => {
  // const dispatch = useDispatch()

  const { userData } = useSelector((state) => state.user);

  return (
    <div
      key={index}
      className={`chat-card ${item?.id === selectedChat?.id ? "active" : ""}`}
      onClick={() => handleCardClick(index, item)}
    >
      <div>
        <div
          className={`d-flex py-2 ${
            !toggle ? "justify-content-between" : "justify-content-center"
          }`}
        >
          <div className="d-flex align-items-center gap-2">
            <img
              className="rounded-circle mr-2"
              src={item?.chatWithDetails?.profile_photo_path || avatar}
              alt="avatar"
              width={32}
              height={32}
            />
            <div className={!toggle ? `d-flex flex-row` : `d-none`}>
              <div className="d-flex flex-column">
                <h5 className="heading">{item?.chatWithDetails?.name}</h5>
                {item?.lastMessage && (
                  <p className="message">
                    {item?.lastMessage?.substring(0, 25)}...
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              !toggle ? `d-flex flex-column align-items-center gap-2` : `d-none`
            }
          >
            {item[userData?.id] > 0 && (
              <span className="notification">{item[userData?.id]}</span>
            )}
            <span className="time">
              {item?.updatedAt?.seconds &&
                moment.unix(item?.updatedAt.seconds).format("hh:mm A")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleChat;
