import React, { useState } from 'react';

const ImagesSection = ({ businessDetails, images }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImagePreview = (img) => {
        setSelectedImage(img);
    };

    return (
        <div className="row service-image-section mb-md-5 mb-3">
            <div className="col-md-8 cols-12">
                <img
                    onClick={() => handleImagePreview(images[0]?.photo_path)}
                    data-bs-toggle="modal"
                    data-bs-target="#imagePreviewModal"
                    src={images[0]?.photo_path}
                    className="service-main-image"
                />
            </div>
            {images?.length > 1 && (
                <div className="col-md-4 cols-12 d-flex flex-md-column justify-content-between mt-md-0 mt-3">
                    {images?.slice(1, 3).map((item, index) => (
                        <div className="service-sub-image" key={index}>
                            {index === 1 && images?.length > 3 && (
                                <div
                                    className="overlay pointer cp"
                                    data-bs-toggle="modal"
                                    data-bs-target="#businessImagesModal"
                                >
                                    <p className="overlay-text text-white fw-medium f-30 poppins m-0">
                                        +{images?.length - 3}
                                    </p>
                                </div>
                            )}
                            <img
                                onClick={() =>
                                    handleImagePreview(item?.photo_path)
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#imagePreviewModal"
                                src={item?.photo_path}
                                className="w-100 h-100 "
                            />
                        </div>
                    ))}
                </div>
            )}
            {/* ========Images Modal=========== */}
            <div
                className="modal fade "
                id="businessImagesModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content rounded-4 p-4 ">
                        <div className="d-flex justify-content-between flex-wrap border-bottom p-2 mb-3">
                            <h2>{businessDetails?.name} Gallery</h2>
                            <svg
                                role="button"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="35"
                                    height="35"
                                    rx="17.5"
                                    stroke="#E1E6EF"
                                />
                                <path
                                    d="M22.8538 22.1465C22.9002 22.193 22.9371 22.2481 22.9622 22.3088C22.9874 22.3695 23.0003 22.4346 23.0003 22.5003C23.0003 22.566 22.9874 22.631 22.9622 22.6917C22.9371 22.7524 22.9002 22.8076 22.8538 22.854C22.8073 22.9005 22.7522 22.9373 22.6915 22.9625C22.6308 22.9876 22.5657 23.0006 22.5 23.0006C22.4343 23.0006 22.3693 22.9876 22.3086 22.9625C22.2479 22.9373 22.1927 22.9005 22.1463 22.854L18 18.7072L13.8538 22.854C13.76 22.9478 13.6327 23.0006 13.5 23.0006C13.3674 23.0006 13.2401 22.9478 13.1463 22.854C13.0525 22.7602 12.9998 22.633 12.9998 22.5003C12.9998 22.3676 13.0525 22.2403 13.1463 22.1465L17.2932 18.0003L13.1463 13.854C13.0525 13.7602 12.9998 13.633 12.9998 13.5003C12.9998 13.3676 13.0525 13.2403 13.1463 13.1465C13.2401 13.0527 13.3674 13 13.5 13C13.6327 13 13.76 13.0527 13.8538 13.1465L18 17.2934L22.1463 13.1465C22.2401 13.0527 22.3674 13 22.5 13C22.6327 13 22.76 13.0527 22.8538 13.1465C22.9476 13.2403 23.0003 13.3676 23.0003 13.5003C23.0003 13.633 22.9476 13.7602 22.8538 13.854L18.7069 18.0003L22.8538 22.1465Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-3 flex-wrap ">
                            {images?.map((item, index) => (
                                <div
                                    className="modal-service-image"
                                    key={index}
                                >
                                    <img
                                        onClick={() =>
                                            handleImagePreview(item?.photo_path)
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#imagePreviewModal"
                                        src={item?.photo_path}
                                        className="w-100 h-100 "
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* ========Image Preview Modal=========== */}
            <div
                className="modal fade"
                id="imagePreviewModal"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    style={{ height: '100vh' }}
                >
                    <div className="modal-content rounded-4 p-1">
                        <div className="d-flex justify-content-between flex-wrap border-bottom p-2 mb-2">
                            <h4>Image Preview</h4>
                            <svg
                                role="button"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="35"
                                    height="35"
                                    rx="17.5"
                                    stroke="#E1E6EF"
                                />
                                <path
                                    d="M22.8538 22.1465C22.9002 22.193 22.9371 22.2481 22.9622 22.3088C22.9874 22.3695 23.0003 22.4346 23.0003 22.5003C23.0003 22.566 22.9874 22.631 22.9622 22.6917C22.9371 22.7524 22.9002 22.8076 22.8538 22.854C22.8073 22.9005 22.7522 22.9373 22.6915 22.9625C22.6308 22.9876 22.5657 23.0006 22.5 23.0006C22.4343 23.0006 22.3693 22.9876 22.3086 22.9625C22.2479 22.9373 22.1927 22.9005 22.1463 22.854L18 18.7072L13.8538 22.854C13.76 22.9478 13.6327 23.0006 13.5 23.0006C13.3674 23.0006 13.2401 22.9478 13.1463 22.854C13.0525 22.7602 12.9998 22.633 12.9998 22.5003C12.9998 22.3676 13.0525 22.2403 13.1463 22.1465L17.2932 18.0003L13.1463 13.854C13.0525 13.7602 12.9998 13.633 12.9998 13.5003C12.9998 13.3676 13.0525 13.2403 13.1463 13.1465C13.2401 13.0527 13.3674 13 13.5 13C13.6327 13 13.76 13.0527 13.8538 13.1465L18 17.2934L22.1463 13.1465C22.2401 13.0527 22.3674 13 22.5 13C22.6327 13 22.76 13.0527 22.8538 13.1465C22.9476 13.2403 23.0003 13.3676 23.0003 13.5003C23.0003 13.633 22.9476 13.7602 22.8538 13.854L18.7069 18.0003L22.8538 22.1465Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            {selectedImage && (
                                <div className="w-100 h-100 d-flex justify-content-center align-items-center position-relative">
                                    <img
                                        src={selectedImage}
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            width: '90vw',
                                            height: '90vh',
                                        }}
                                        alt="Enlarged Preview"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagesSection;
