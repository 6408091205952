import React from 'react';
import { CloseIcon } from '../components/setup-guide/svg';
import moment from 'moment';
import { convertTo12HourFormat, isReviewable } from '../helpers/constants';
import { useDispatch } from 'react-redux';
import {
    cancelAppointment,
    getCustomerAppointments,
} from '../redux/appointments/appointmentAction';
import { getAllBusinessAppointments } from '../redux/calendar/actionCreator';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

const ManageAppointment = ({
    toggle,
    isOpen,
    selectedAppointment,
    setSelectedAppointment,
}) => {
    const dispatch = useDispatch();
    const { isCancellingAppointment } = useSelector(
        (state) => state.appointments
    );
    console.log('Selected appointment', selectedAppointment);

    const getCancellationText = () => {
        if (
            selectedAppointment?.appointmentService[0]?.service?.business?.cancellationType.toLowerCase() ==
            'strict'
        ) {
            return 'Please perform cancellation within 24 hours of appointment time to receive full refund.';
        } else if (
            selectedAppointment?.appointmentService[0]?.service?.business?.cancellationType.toLowerCase() ==
            'lenient'
        ) {
            return 'Please perform cancellation within 72 hours of appointment time to receive full refund';
        } else {
            return 'Please perform cancellation within 48 hours of appointment time to receive full refund.';
        }
    };

    const checkRemainingHours = (targetDate, targetTime) => {
        // Combine date and time into a single moment object
        const targetDateTime = moment(targetDate).set({
            hour: parseInt(targetTime.split(':')[0]),
            minute: parseInt(targetTime.split(':')[1]),
        });

        // Current date and time
        const now = moment();

        // Calculate the difference in hours
        const diffInHours = targetDateTime.diff(now, 'hours', true);
        console.log('Differe', diffInHours);
        let fullRefund = `You will receive refund of $${selectedAppointment?.amount} in next 30 mins`;
        let noRefund = 'You will not receive refund for this appointment';

        if (
            selectedAppointment?.appointmentService[0]?.service?.business?.cancellationType.toLowerCase() ==
            'strict'
        ) {
            if (diffInHours > 24) {
                return noRefund;
            } else {
                return fullRefund;
            }
        } else if (
            selectedAppointment?.appointmentService[0]?.service?.business?.cancellationType.toLowerCase() ==
            'lenient'
        ) {
            if (diffInHours > 72) {
                return noRefund;
            } else {
                return fullRefund;
            }
        } else if (
            selectedAppointment?.appointmentService[0]?.service?.business?.cancellationType.toLowerCase() ==
            'moderate'
        ) {
            if (diffInHours > 48) {
                return noRefund;
            } else {
                return noRefund;
            }
        } else {
            return fullRefund;
        }
    };
    return (
        <div
            className={`modal manage-appointment-modal right-modal ${
                !isOpen ? 'd-none' : ''
            }`}
        >
            <div className={`right-modal-content`}>
                <div className={`right-modal-header`}>
                    <div className={`container-fluid`}>
                        <div className={`row align-items-center`}>
                            <div className={`col px-0`}>
                                <h3
                                    className={`fs-22 fw-normal poly c-334155 m-0`}
                                >
                                    Manage Appointment
                                </h3>
                            </div>
                            <div className={`col-auto px-0`}>
                                <div className={`cp`} onClick={toggle}>
                                    <CloseIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`right-modal-body`}>
                    <div className={`container-fluid`}>
                        <p className="poppins fw-600 fs-16  c-0f151d mb-2 mt-2 mt-md-3">
                            Appointment Details
                        </p>

                        {selectedAppointment?.appointmentService.map(
                            (item, idx) => {
                                return (
                                    <p
                                        className="poppins c-667085 fs-16 fw-400 mb-1  "
                                        key={idx}
                                    >
                                        {item?.service?.name}
                                    </p>
                                );
                            }
                        )}

                        <div className="d-flex align-items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                            >
                                <path
                                    d="M12.625 1.25H10.9375V0.6875C10.9375 0.538316 10.8782 0.395242 10.7727 0.289752C10.6673 0.184263 10.5242 0.125 10.375 0.125C10.2258 0.125 10.0827 0.184263 9.97725 0.289752C9.87176 0.395242 9.8125 0.538316 9.8125 0.6875V1.25H4.1875V0.6875C4.1875 0.538316 4.12824 0.395242 4.02275 0.289752C3.91726 0.184263 3.77418 0.125 3.625 0.125C3.47582 0.125 3.33274 0.184263 3.22725 0.289752C3.12176 0.395242 3.0625 0.538316 3.0625 0.6875V1.25H1.375C1.07663 1.25 0.790483 1.36853 0.579505 1.5795C0.368526 1.79048 0.25 2.07663 0.25 2.375V13.625C0.25 13.9234 0.368526 14.2095 0.579505 14.4205C0.790483 14.6315 1.07663 14.75 1.375 14.75H12.625C12.9234 14.75 13.2095 14.6315 13.4205 14.4205C13.6315 14.2095 13.75 13.9234 13.75 13.625V2.375C13.75 2.07663 13.6315 1.79048 13.4205 1.5795C13.2095 1.36853 12.9234 1.25 12.625 1.25ZM3.0625 2.375V2.9375C3.0625 3.08668 3.12176 3.22976 3.22725 3.33525C3.33274 3.44074 3.47582 3.5 3.625 3.5C3.77418 3.5 3.91726 3.44074 4.02275 3.33525C4.12824 3.22976 4.1875 3.08668 4.1875 2.9375V2.375H9.8125V2.9375C9.8125 3.08668 9.87176 3.22976 9.97725 3.33525C10.0827 3.44074 10.2258 3.5 10.375 3.5C10.5242 3.5 10.6673 3.44074 10.7727 3.33525C10.8782 3.22976 10.9375 3.08668 10.9375 2.9375V2.375H12.625V4.625H1.375V2.375H3.0625ZM12.625 13.625H1.375V5.75H12.625V13.625Z"
                                    fill="#667085"
                                />
                            </svg>

                            <span className="poppins fs-16 fw-400 c-667085 ms-2">
                                {selectedAppointment?.date &&
                                    moment(selectedAppointment?.date).format(
                                        'DD/MM/YYYY'
                                    )}
                            </span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1"
                                height="20"
                                viewBox="0 0 1 20"
                                fill="none"
                                className="mx-3"
                            >
                                <line
                                    x1="0.5"
                                    x2="0.5"
                                    y2="20"
                                    stroke="#D7E2F2"
                                />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                            >
                                <path
                                    d="M8 0.6875C6.55373 0.6875 5.13993 1.11637 3.9374 1.91988C2.73486 2.72339 1.7976 3.86544 1.24413 5.20163C0.690668 6.53781 0.545857 8.00811 0.828011 9.4266C1.11017 10.8451 1.80661 12.148 2.82928 13.1707C3.85196 14.1934 5.15492 14.8898 6.57341 15.172C7.99189 15.4541 9.46219 15.3093 10.7984 14.7559C12.1346 14.2024 13.2766 13.2651 14.0801 12.0626C14.8836 10.8601 15.3125 9.44628 15.3125 8C15.3105 6.06123 14.5394 4.20246 13.1685 2.83154C11.7975 1.46063 9.93877 0.689547 8 0.6875ZM8 14.1875C6.77623 14.1875 5.57994 13.8246 4.56241 13.1447C3.54488 12.4648 2.75182 11.4985 2.2835 10.3679C1.81518 9.23724 1.69265 7.99314 1.93139 6.79288C2.17014 5.59262 2.75944 4.49011 3.62478 3.62478C4.49012 2.75944 5.59262 2.17014 6.79288 1.93139C7.99314 1.69264 9.23724 1.81518 10.3679 2.2835C11.4985 2.75181 12.4648 3.54488 13.1447 4.56241C13.8246 5.57994 14.1875 6.77623 14.1875 8C14.1856 9.64046 13.5331 11.2132 12.3732 12.3732C11.2132 13.5331 9.64046 14.1856 8 14.1875ZM12.5 8C12.5 8.14918 12.4407 8.29226 12.3353 8.39775C12.2298 8.50324 12.0867 8.5625 11.9375 8.5625H8C7.85082 8.5625 7.70775 8.50324 7.60226 8.39775C7.49677 8.29226 7.4375 8.14918 7.4375 8V4.0625C7.4375 3.91332 7.49677 3.77024 7.60226 3.66475C7.70775 3.55926 7.85082 3.5 8 3.5C8.14919 3.5 8.29226 3.55926 8.39775 3.66475C8.50324 3.77024 8.5625 3.91332 8.5625 4.0625V7.4375H11.9375C12.0867 7.4375 12.2298 7.49676 12.3353 7.60225C12.4407 7.70774 12.5 7.85082 12.5 8Z"
                                    fill="#667085"
                                />
                            </svg>

                            <span className="poppins fs-16 fw-400 c-667085 ms-2">
                                {selectedAppointment?.time &&
                                    convertTo12HourFormat(
                                        selectedAppointment?.time
                                    )}
                            </span>
                        </div>

                        {selectedAppointment?.status &&
                            // selectedAppointment?.status != 'pending' &&
                            selectedAppointment?.status != 'completed' && (
                                <>
                                    <p className="poppins fw-600 fs-16  c-0f151d mb-2 mt-5">
                                        Cancel your appointment
                                    </p>

                                    <p className="poppins fs-14 fw-600 c-334155 mb-1">
                                        Cancellation Policy
                                    </p>
                                    {selectedAppointment?.appointmentService
                                        ?.length > 0 && (
                                        <span className="poppins fs-14 c-667085 fw-400">
                                            {getCancellationText()}
                                        </span>
                                    )}

                                    <p className="poppins fs-14 fw-600 c-334155 mb-1 mt-2">
                                        Refund Policy
                                    </p>

                                    {selectedAppointment?.date &&
                                        selectedAppointment?.time &&
                                        selectedAppointment?.appointmentService
                                            ?.length > 0 && (
                                            <span className="poppins fs-14 c-667085 fw-400">
                                                {checkRemainingHours(
                                                    selectedAppointment?.date,
                                                    selectedAppointment?.time
                                                )}
                                            </span>
                                        )}

                                    <div>
                                        {selectedAppointment.status !==
                                            'canceled' && (
                                            <button
                                                className="cancel-appointment-btn fs-16 poppins mt-5 mb-2 mb-md-3"
                                                onClick={() => {
                                                    dispatch(
                                                        cancelAppointment(
                                                            selectedAppointment?.id,
                                                            () => {
                                                                toggle();
                                                                setSelectedAppointment(
                                                                    null
                                                                );
                                                            }
                                                        )
                                                    );
                                                }}
                                                disabled={
                                                    isCancellingAppointment
                                                }
                                            >
                                                {isCancellingAppointment ? (
                                                    <Spin />
                                                ) : (
                                                    'Cancel Appointment'
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageAppointment;
