import React, { useEffect } from 'react';
import LocationSearch from '../../components/locationSearch/LocationSearch';
import { useState } from 'react';

const Location = ({
    isOnline,
    setIsOnline,
    handlePrev,
    formData,
    setFormData,
    handleNext,
}) => {
    const [locationError, setLocationError] = useState(false);
    const [inputVal, setInputVal] = useState('');
    const handleLocationLatLng = ({ lat, lng, address }) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            longitude: lng,
            latitude: lat,
            address,
        }));
        setLocationError(false);
    };

    const handleIsOnline = (e) => {
        let checked = e.target.checked;
        if (checked) {
            setIsOnline(true);
            setLocationError(false);
            setInputVal('');
        } else {
            setIsOnline(false);
        }
    };

    const handleSubmit = async () => {
        if (!isOnline && formData?.address && formData?.latitude) {
            handleNext();
        } else if (isOnline) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                longitude: '',
                latitude: '',
                address: '',
            }));
            handleNext();
        } else {
            setLocationError(true);
        }
    };

    useEffect(() => {
        setInputVal(formData?.address);
        // setIsOnline(formData?.address ? '' : true)
    }, []);

    return (
        <div className={`container-fluid max-w-md-605`}>
            <div className={`row justify-content-center pb-md-40 pb-3`}>
                <div className={`col-md-12 px-0 text-center pt-4 pb-16`}>
                    <h5 className={`m-0 c-334155 poly f-36 fw-normal lh-48`}>
                        Set your location
                    </h5>
                </div>
                <div className={`col-md-7 col-12 px-0 text-center lh-0`}>
                    <span
                        className={`m-0 c-667085 poppins f-14 fw-normal lh-20`}
                    >
                        Add your business location so your clients can easily
                        find you.
                    </span>
                </div>
            </div>
            <div className={`row`}>
                <div className={`col-12 px-md-0`}>
                    <div className={`card-step`}>
                        <div className={`row mb-5`}>
                            <div className={`col-md-12 pb-20`}>
                                <label
                                    htmlFor="businessName"
                                    className="form-label c-667085 poppins f-14 fw-400 lh-20"
                                >
                                    Business Location{' '}
                                    <span className={`text-danger`}>*</span>
                                </label>
                                <LocationSearch
                                    value={inputVal}
                                    setValue={setInputVal}
                                    onPlaceSelected={handleLocationLatLng}
                                    isOnline={isOnline}
                                />
                                {locationError && (
                                    <p className="text-danger">Required</p>
                                )}
                            </div>

                            {/* <div className={`col-md-12`}>
                <div className="form-check d-flex align-items-md-start align-items-center">
                  <input
                    className="form-check-input check-box-custom cp"
                    type="checkbox"
                    checked={isOnline}
                    onChange={handleIsOnline}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label c-667085 poppins f-14 fw-normal lh-23 ps-2 cp"
                    htmlFor="flexCheckDefault"
                  >
                    i don’t have a business address (mobile and online services
                    only)
                  </label>
                </div>
              </div> */}
                        </div>
                        <div className={`row`}>
                            <div
                                className={`col-md-6 col-6 pr-md-7-5 `}
                                onClick={handlePrev}
                            >
                                <button className={`btn btn-back`}>Back</button>
                            </div>
                            <div
                                className={`col-md-6 col-6 pl-md-7-5 pb-md-0 pb-3`}
                                onClick={handleSubmit}
                            >
                                <button className={`btn btn-next`}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
