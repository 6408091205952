import {
    addDoc,
    collection,
    doc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    updateDoc,
    where,
    FieldValue,
    increment,
    getDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../../config/api/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../config/api/firebase';
import {
    GET_CHAT_BY_USER_ID,
    GET_MESSAGES_BY_CHAT_ID,
    SET_CHAT_LOADING,
    SET_GET_MESSAGES_LOADING,
    SET_INITIALIZING_CHAT_LOADING,
    SET_SEND_MESSAGE_LOADER,
} from './types';
import moment from 'moment';
import { axiosInstance } from '../../repository/Repository';
const chatCollectionRef = collection(db, 'chat');
const userCollectionRef = collection(db, 'users');

export const setChatLoading = (val) => async (dispatch) => {
    dispatch({ type: SET_CHAT_LOADING, payload: val });
};

export const getChatsByUserId = (userId) => async (dispatch) => {
    try {
        // Dispatch action to set loading state
        dispatch(setChatLoading(true));

        // Create a query to fetch chats where the user is a member
        const userChatsQuery = query(
            chatCollectionRef,
            where('members', 'array-contains', userId),
            orderBy('updatedAt', 'desc')
        );

        // Subscribe to the query snapshot
        const unsubscribe = onSnapshot(
            userChatsQuery,
            async (querySnapshot) => {
                const chats = [];

                for (let doc of querySnapshot.docs) {
                    let { members } = doc.data();
                    let otherUserId = members.find(
                        (memberId) => memberId !== userId
                    );

                    let userQuery = query(
                        userCollectionRef,
                        where('id', '==', otherUserId)
                    );

                    const userDoc = await getDocs(userQuery);

                    if (userDoc.size > 0) {
                        chats.push({
                            id: doc.id,
                            ...doc.data(),
                            chatWithDetails: {
                                id: userDoc.docs[0].id,
                                ...userDoc.docs[0].data(),
                            },
                        });
                    }
                }

                dispatch({ type: GET_CHAT_BY_USER_ID, payload: chats });
                dispatch(setChatLoading(false));
            }
        );

        return unsubscribe;
    } catch (error) {
        dispatch(setChatLoading(false));
        dispatch({ type: GET_CHAT_BY_USER_ID, payload: [] });

        toast.error(error?.message);
    }
};

export const setInitializeChatLoader = (val) => async (dispatch) => {
    dispatch({ type: SET_INITIALIZING_CHAT_LOADING, payload: val });
};

export const initializeChat =
    (senderId, receiverId, redirect, history) => async (dispatch) => {
        // Construct a query to find chats where both user IDs are present
        dispatch(setInitializeChatLoader(true));
        try {
            const chatQuery = query(
                chatCollectionRef,
                where('members', 'array-contains', senderId)
            );

            let chatObj = {
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                [senderId]: 0,
                [receiverId]: 0,
                lastMessage: '',
                members: [senderId, receiverId],
            };

            // Get chat documents
            const chatDocs = await getDocs(chatQuery);
            let foundChatDoc = null;

            if (chatDocs.size > 0) {
                for (let doc of chatDocs.docs) {
                    const members = doc.data().members;

                    if (members.includes(receiverId)) {
                        foundChatDoc = doc.id;
                        break;
                    }
                }
                if (foundChatDoc) {
                    history.push(`${redirect}?chatId=${foundChatDoc}`);
                } else {
                    const docRef = await addDoc(chatCollectionRef, chatObj);
                    history.push(`${redirect}?chatId=${docRef.id}`);
                }
                dispatch(setInitializeChatLoader(false));
            } else {
                const docRef = await addDoc(chatCollectionRef, chatObj);
                history.push(`${redirect}?chatId=${docRef.id}`);
                dispatch(setInitializeChatLoader(false));
            }
        } catch (error) {
            toast.error(error?.message);
            dispatch(setInitializeChatLoader(false));
        }
    };

export const setGetMessagesLoader = (val) => async (dispatch) => {
    dispatch({ type: SET_GET_MESSAGES_LOADING, payload: val });
};

//Get messages by chat id
export const getMessagesByChatId =
    (id, userId, chatRef) => async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_MESSAGES_BY_CHAT_ID,
                payload: [],
            });
            dispatch(setGetMessagesLoader(true));
            let { allChats } = getState().chat;

            if (chatRef && chatRef.current != null) {
                chatRef.current();
            }
            chatRef.current = onSnapshot(
                query(
                    collection(chatCollectionRef, id, 'messages'),
                    orderBy('createdAt', 'asc')
                ),
                async (querySnapshot) => {
                    let tempMessages = [];
                    for (let doc of querySnapshot.docs) {
                        const createdAt = moment
                            .unix(doc.data().createdAt?.seconds)
                            .format('DD/MM/YYYY');

                        // Check if there is already a group for the current date
                        const existingGroup = tempMessages.find(
                            (group) => group.date === createdAt
                        );

                        // If a group for the current date exists, push the message to that group
                        if (existingGroup) {
                            existingGroup.groupedMessages.push({
                                id: doc.id,
                                ...doc.data(),
                            });
                        } else {
                            // If no group for the current date exists, create a new group
                            tempMessages.push({
                                date: createdAt,
                                groupedMessages: [
                                    { id: doc.id, ...doc.data() },
                                ],
                            });
                        }
                        // tempMessages.push({ id: doc.id, ...doc.data() });
                    }

                    await updateDoc(doc(chatCollectionRef, id), {
                        [userId]: 0,
                    });
                    let tempArr = [...allChats];

                    let index = tempArr.findIndex(
                        (chatData) => chatData?.id == id
                    );
                    if (index != -1) {
                        tempArr[index] = {
                            ...tempArr[index],
                            [userId]: 0,
                        };
                        dispatch({
                            type: GET_CHAT_BY_USER_ID,
                            payload: tempArr,
                        });
                    }

                    dispatch({
                        type: GET_MESSAGES_BY_CHAT_ID,
                        payload: tempMessages,
                    });
                    dispatch(setGetMessagesLoader(false));
                }
            );
        } catch (error) {
            dispatch({
                type: GET_MESSAGES_BY_CHAT_ID,
                payload: [],
            });

            dispatch(setGetMessagesLoader(false));
            toast.error(error?.message);
        }
    };

export const setSendMessageLoader = (val) => async (dispatch) => {
    dispatch({ type: SET_SEND_MESSAGE_LOADER, payload: val });
};

//
export const sendMessage =
    (chatData, messageData, onSuccess = () => {}, type) =>
    async (dispatch) => {
        try {
            dispatch(setSendMessageLoader(true));

            if (messageData.attachment) {
                const storageRef = ref(
                    storage,
                    `chat/${messageData?.attachment?.name}`
                ); // Create reference with desired path structure
                const uploadTask = await uploadBytes(
                    storageRef,
                    messageData?.attachment
                );
                const downloadURL = await getDownloadURL(uploadTask.ref);

                messageData.attachment = downloadURL;
            }
            const chatDocRef = doc(chatCollectionRef, chatData.id);
            const chatDocSnapshot = await getDoc(chatDocRef);
            const chatDocData = chatDocSnapshot.data();
            const messageRef = await addDoc(
                collection(chatCollectionRef, chatData.id, 'messages'),
                {
                    ...messageData,
                    createdAt: serverTimestamp(),
                }
            );
            let receiverId;
            let updateUnread = {};
            for (let member of chatDocData?.members) {
                if (member != messageData.senderId) {
                    receiverId = member;
                    updateUnread = {
                        ...updateUnread,
                        // [member]: chatDocData[member] + 1,
                        [member]: increment(1),
                        // [member]: chatData[member] + 1,
                    };
                }
            }

            if (Object.keys(updateUnread).length > 0) {
                await updateDoc(doc(chatCollectionRef, chatData?.id), {
                    ...updateUnread,
                    lastMessage: messageData?.message,
                    updatedAt: serverTimestamp(),
                });
            }
            dispatch(setSendMessageLoader(false));
            dispatch(
                sentNotification({
                    type: '01hr',
                    message: 'You have received a new message',
                    user: receiverId,
                    redirectUrl: 'dashboard/chat',
                })
            );
            onSuccess(); // Call onSuccess with the message reference ID
        } catch (error) {
            dispatch(setSendMessageLoader(false));
            toast.error(error?.message);
        }
    };

export const sentNotification = (data) => async (dispatch) => {
    try {
        await axiosInstance.post('/inAppnotifications/add', data);
    } catch (error) {
        throw error;
    }
};
