import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrum from "../../features/breadcrum/BreadCrum";
import {
  getChatsByUserId,
  getMessagesByChatId,
} from "../../redux/Chat/chatAction";
import Navbar from "../Navbar";
import ChatCards from "./ChatCards";
import ChatDetails from "./ChatDetails";
import "./chat.css";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading";

const Chat = () => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);
  const { isGettingChats, allChats } = useSelector((state) => state.chat);

  const [selectedChat, setSelectedChat] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [chatMob, setChatMob] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  let chatRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chatId = searchParams.get("chatId");
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getChatsByUserId(userData?.id));
  }, []);

  const chatOnClick = (chat) => {
    if (chatRef) {
      window.clearTimeout(chatRef.current);
    }
    setSelectedChat(chat);
    dispatch(getMessagesByChatId(chat?.id, userData?.id, chatRef));
    setChatMob(true);
  };

  const loadChatMessages = () => {
    if (!selectedChat?.id) {
      if (chatId) {
        let foundChat = allChats.find(
          (chatDetails) => chatDetails?.id == chatId
        );
        if (foundChat) {
          chatOnClick(foundChat);
        } else {
          if (window.innerWidth > 767.99) {
            console.log("inside1", isMobile);

            chatOnClick(allChats[0]);
          }
        }
      } else {
        if (window.innerWidth > 767.99) {
          console.log("inside2", isMobile);
          chatOnClick(allChats[0]);
        }
      }
    }
  };
  // const updatePredicate = () => {
  //   setIsMobile(window.innerWidth < 767.99);
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", updatePredicate);
  //   return () => window.removeEventListener("resize", updatePredicate);
  // }, []);

  useEffect(() => {
    if (allChats.length > 0) {
      loadChatMessages();
    }
  }, [allChats]);

  return (
    <>
      {userData.role === "business" && <Navbar />}
      {isGettingChats ? (
        <Loading />
      ) : (
        // <div className="d-flex justify-content-center align-items-center">
        //   <Spin className="pt-40 pb-40" />
        // </div>
        <>
          <div
            className={`px-5 pb-5 bg-white ${
              userData.role === "business" ? "container-fluid" : "container"
            }`}
          >
            <BreadCrum
              items={[
                {
                  title: "Appointments",
                  path: "/customer/upcoming-appointments",
                },
                { title: "My Messages", path: "/customer/chat" },
              ]}
            />
            <div className="row d-flex">
              <div
                className={`col-12 d-md-block p-0 ${
                  chatMob ? "d-none" : "d-block"
                } ${!toggle ? "col-lg-3 col-md-4" : "col-lg-1"}`}
              >
                <ChatCards
                  toggle={toggle}
                  setToggle={setToggle}
                  setChatMob={setChatMob}
                  chatOnClick={chatOnClick}
                  selectedChat={selectedChat}
                />
              </div>
              <div
                className={`col-12 d-md-block p-0 ${
                  chatMob ? "d-block" : "d-none"
                } ${!toggle ? "col-lg-9 col-md-8" : "col-lg-11"}`}
              >
                <ChatDetails
                  selectedChat={selectedChat}
                  setChatMob={setChatMob}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Chat;
