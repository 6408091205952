import axios from "axios"
import { getToken } from "../helpers/functions";

// axios.defaults.baseURL = `http://localhost:3000/`
// axios.defaults.baseURL = `https://app.tisume.com/`
axios.defaults.baseURL = `https://tissume-backend-live-525e1418a8b5.herokuapp.com/`
// axios.defaults.baseURL = `https://tisume-backend-cd4dfc06d61e.herokuapp.com/`
axios.defaults.headers.common["Authorization"] = getToken()
axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.headers.common['development'] = 'true'

export default axios
