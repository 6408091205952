import React, { useEffect, useState } from 'react';
import './index.css';
import { Rating } from 'react-simple-star-rating';
import { Button, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { postReview } from '../../redux/Reviews/reviewsAction';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const WriteReview = () => {

    const { isLoading } = useSelector((state) => state.reviews);

    const location = useLocation();
    const dispatch = useDispatch()
    const history = useHistory();

    const { business, appointment } = location.state || {};

    const [review, setReview] = useState({
        rating: 5,
        description: '',
        appointment: '',
        business: '',
    })
    // console.log('review: ', review);
    const [stars, setStars] = useState(null)

    const handleRating = (rate) => {
        setStars(rate)
        setReview((prev) => ({ ...prev, rating: rate }))
    }

    const handleInput = (e) => {
        const { name, value } = e.target
        setReview((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!review.description.trim()) {
            toast.info('please add review')
            return
        }
        if (review.rating == 0) {
            toast.info('Rating should be atleast 0.5 star')
            return
        }
        dispatch(postReview(review))
            .then((res) => {
                console.log('res: ', res);
                if (res.status == 201) {
                    setReview({
                        rating: '',
                        description: '',
                        appointment: '',
                        business: '',
                    })
                    toast.success('Review posted successfully')
                    history.push('/customer/upcoming-appointments')
                }
            }).catch((err) => {
                console.log('error while posting review', err);
            })
    }

    useEffect(() => {
        setReview((prev) => ({
            ...prev,
            appointment: appointment,
            business: business,
        }))
    }, [location.state])

    return (
        <div className="container p-4">
            <div>
                <h2 className="write-review-heading">Write a Review</h2>
                <p className="review-heading-desc">
                    Tell your stylists how you feel about their services
                </p>
                <form className="write-review-container" onSubmit={handleSubmit}>
                    <div className="give-rating-container">
                        <h2>Give a Rating</h2>
                        <div className="reviewStars">
                            <Rating
                                initialValue={5}
                                allowFraction
                                fillColor="#FFC931"
                                size={18}
                                onClick={handleRating}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="review" className='d-block'>Enter your review here</label>
                        <textarea
                            name="description"
                            id="description"
                            cols="30"
                            rows="10"
                            placeholder='Enter review'
                            onChange={handleInput}
                            maxLength={255}
                        ></textarea>
                    </div>
                    <div className='d-flex gap-3 justify-content-between flex-sm-nowrap flex-wrap'>
                        <button
                            className='btn back-btn order-sm-1 order-2'
                            type='button'
                            onClick={() => history.push('/customer/upcoming-appointments')}
                        >
                            Back
                        </button>

                        <button
                            className='btn send-review-btn order-sm-2 order-1'
                            type='submit'
                            disabled={isLoading}
                        >
                            {isLoading ? <Spin /> : 'Send Review'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default WriteReview;
