import './businessReview.css';
import Navbar from '../Navbar';
import BusinessReviewsCard from './BusinessReviewsCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getReviews } from '../../redux/Reviews/reviewsAction';
import { Spin } from 'antd';

const BusinessReviewsLayout = () => {
    const { userData } = useSelector((state) => state.user);
    const { allReviews, isLoading } = useSelector((state) => state.reviews);

    const dispatch = useDispatch();
    useEffect(() => {
        if (userData) {
            dispatch(getReviews(userData?.business?.id));
        }
    }, [userData]);

    return (
        <div className={`container-fluid`}>
            <div className={`row`}>
                <div
                    className={`col-12 px-0 bg-ff br-top-left-22 services-cont`}
                >
                    <div className={`container-fluid`}>
                        <Navbar />
                    </div>

                    <div className={`mt-0 mx-md-24 mb-20 px-md-80 px-16`}>
                        <h6 className={`mb-8 f-28 fw-normal poly c-0f151d`}>
                            Customer Reviews
                        </h6>

                        {isLoading ? (
                            <div className="d-flex align-items-center justify-content-center">
                                <Spin />
                            </div>
                        ) : allReviews.length > 0 ? (
                            <div
                                className={`border-1-md-e1e6ef br-24 py-4 px-0 px-md-3`}
                            >
                                <div className={`container-fluid`}>
                                    <div className="row">
                                        {/* <div className='col-12 col-sm-5 col-lg-4 col-xl-3 pb-2 pb-sm-0'> */}
                                        {allReviews.map((item, index) => (
                                            <div
                                                className="col-12 col-lg-4 col-sm-6 pb-2 pb-sm-0"
                                                key={index}
                                            >
                                                <BusinessReviewsCard
                                                    item={item}
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                        ))}

                                        {/* <div className='col-12 col-sm-7 col-lg-8 col-xl-9'>
                                                <BusinessReviewsDetails
                                                    user={reviewDetails?.user}
                                                    appointments={reviewDetails?.appointments}
                                                    isLoading={isLoading}
                                                />
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="poppins f-14 c-667085">
                                This business has no reviews
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessReviewsLayout;
