import React from "react";

const About = ({ about }) => {
  return (
    <div className="">
      <p className="mb-4 mt-3 poly f-36 ">About</p>
      <p className="mb-2 poppins f-14 c-667085">{about}</p>
    </div>
  );
};

export default About;
