import {
    GET_AUTOMATED_SETTING_ERROR,
    GET_AUTOMATED_SETTING_START,
    GET_AUTOMATED_SETTING_SUCCESS,
    GET_DETAILS,
    GET_PENDING,
    GET_PRICING_ERROR,
    GET_PRICING_START,
    GET_PRICING_SUCCESS,
    GET_REJECTED,
    INSTA_RES,
    RESET_INSTA_FILES,
    UPDATE_AUTOMATED_SETTING_ERROR,
    UPDATE_AUTOMATED_SETTING_START,
    UPDATE_AUTOMATED_SETTING_SUCCESS,
    UPDATE_DETAILS,
    UPDATE_PENDING,
    UPDATE_REJECTED,
} from './types';

const initialState = {
    businessDetails: null,
    fetchLoading: false,
    updateLoading: false,
    isSettingUpdating: false,
    instaImgFiles: [],
    isLoading: false,
    automatedSettings: null,
    isPriceLoading: false,
    pricing: [],
};

const detailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PENDING:
            return {
                ...state,
                fetchLoading: true,
            };
        case RESET_INSTA_FILES:
            return {
                ...state,
                instaImgFiles: [],
            };
        case INSTA_RES:
            return {
                ...state,
                instaImgFiles: action.payload,
            };
        case GET_DETAILS:
            return {
                ...state,
                fetchLoading: false,
                businessDetails: action.payload,
            };
        case GET_REJECTED:
            return {
                ...state,
                fetchLoading: false,
            };

        //Update Info /delete Images
        case UPDATE_PENDING:
            return {
                ...state,
                updateLoading: true,
            };
        case UPDATE_DETAILS:
            return {
                ...state,
                updateLoading: false,
            };
        case UPDATE_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        case GET_AUTOMATED_SETTING_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_AUTOMATED_SETTING_SUCCESS:
            return {
                ...state,
                automatedSettings: action.payload,
                isLoading: false,
            };
        case GET_AUTOMATED_SETTING_ERROR:
            return {
                ...state,
                automatedSettings: null,
                isLoading: false,
            };
        case UPDATE_AUTOMATED_SETTING_START:
            return {
                ...state,
                isSettingUpdating: true,
            };
        case UPDATE_AUTOMATED_SETTING_SUCCESS:
        case UPDATE_AUTOMATED_SETTING_ERROR:
            return {
                ...state,
                isSettingUpdating: false,
            };
        case GET_PRICING_START:
            return {
                ...state,
                isPriceLoading: true,
                pricing: [],
            };
        case GET_PRICING_SUCCESS:
            return {
                ...state,
                isPriceLoading: false,
                pricing: action.payload,
            };
        case GET_PRICING_ERROR:
            return {
                ...state,
                isPriceLoading: false,
            };

        default:
            return state;
    }
};

export default detailsReducer;
