import React from "react";
import "./reviews.css";
import image1 from "../../assets/images/reviews/image1.png";
import image2 from "../../assets/images/reviews/image2.png";
import ReviewCard from "./review-card";

const reviews = ({ reviews }) => {
  const items = [
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image1,
    },

    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image1,
    },

    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
    {
      name: "Dawn Nicolas DVM",
      review:
        "We had the couples workshop for full body Swedish massage run by Victoria. She was an excellent coach - clear, thorough, patient and very professional.",
      date: "10 July 2023",
      image: image2,
    },
  ];
  return (
    <div className="">
      <p className="mb-4 mt-3 poly f-36 me-2">Reviews</p>
      <div className="reviews-section">
        <div className="reviews-container">
          <div className="me-4">
            {(!reviews || reviews.length === 0) ? <p>This business has no reviews</p> :
              reviews?.map((item, idx) => {
                return (
                  <div key={idx}>
                    <ReviewCard item={item} />
                    {idx + 1 != items?.length && (
                      <hr className="my-4 review-hr " />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default reviews;
