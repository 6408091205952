import { rescheduleAppointment } from './appointmentAction';
import {
    BOOK_APPOINTMENT_ERROR,
    BOOK_APPOINTMENT_START,
    BOOK_APPOINTMENT_SUCCESS,
    GET_BUSINESS_APP_ERROR,
    GET_BUSINESS_APP_START,
    GET_BUSINESS_APP_SUCCESS,
    GET_APPOINTMENTS,
    PENDING_APPOINTMENTS,
    REJECTED_APPOINTMENTS,
    CANCEL_APPOINTMENT_LOADING,
    PREVIOUS_APP_PENDING,
    PREVIOUS_APP_ERROR,
    PREVIOUS_APP_SUCCESS,
    RESCHEDULE_APPOINTMENT_LOADING
} from './types';

const initialState = {
    cutomerAppointments: [],
    isLoading: false,
    todayAppointments: [],
    upcomingAppointments: [],
    previousAppointments: [],
    isCancellingAppointment: false,
    isEditingAppointment: false,

};

const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case BOOK_APPOINTMENT_START:
        case PENDING_APPOINTMENTS:
        case PREVIOUS_APP_PENDING:
        case GET_BUSINESS_APP_START:
            return {
                ...state,
                isLoading: true,
            };

        // =======GET BUSINESS APPOINTMENT=========
        case GET_BUSINESS_APP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                todayAppointments: action.payload.todayAppointments,
                upcomingAppointments: action.payload.upcomingAppointments,
            };

        // =======GET CUSTOMER APPOINTMENTS=========
        case GET_APPOINTMENTS:
            return {
                ...state,
                isLoading: false,
                cutomerAppointments: action.payload,
            };

        // =======PREVIOUS APPOINTMENTS=========
        case PREVIOUS_APP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                previousAppointments: action.payload,
            };
        case CANCEL_APPOINTMENT_LOADING:
            return {
                ...state,
                isCancellingAppointment: action.payload,
            };

        case BOOK_APPOINTMENT_SUCCESS:
        case BOOK_APPOINTMENT_ERROR:
        case GET_BUSINESS_APP_ERROR:
        case REJECTED_APPOINTMENTS:
        case PREVIOUS_APP_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        // =======RESCHEDULE BUSINESS APPOINTMENT=========
        case RESCHEDULE_APPOINTMENT_LOADING:
            return {
                ...state,
                isEditingAppointment: action.payload,
            };

        default:
            return state;
    }
};

export default appointmentReducer;
