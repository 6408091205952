import React from 'react';
import '../assets/css/app.css';
import './Paymentmethod.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCard from './StripeCard';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { MasterCardSvg, VisaCardSvg } from './profile-settings/svgs';
import { getCustomerDetails } from '../redux/users/actionCreator';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
const stripePromise = loadStripe(process.env.REACT_APP_Stripe_Key);

const PaymentMethod = ({
    getToken,
    setGetToken,
    setStripeToken,
    notes,
    setNotes,
    cardId,
    setCardId,
    setPaymentMethodId,
}) => {
    const { userData } = useSelector((state) => state.user);
    console.log('userData: ', userData);

    const dispatch = useDispatch()
    const [paymentMethod, setPaymentMethod] = useState('existing');
    const handleExistingCard = (id) => {
        setCardId(id);
    };

    useEffect(() => {
        if (userData && userData?.cards?.length === 0) {
            setPaymentMethod('new');
        }
    }, [userData]);

    useEffect(() => {
        dispatch(getCustomerDetails());
    }, []);

    return (
        <div>
            <div>
                <h1 className="lh-40 poly f-36">Review and Confirm</h1>
            </div>
            {!userData?.cards ?
                <div className='d-flex align-items-center justify-content-center mt-5'>
                    <Spin />
                </div>
                :
                <>
                    <div className="my-4">
                        <h2 className="poppins fw-600 f-18 lh-24">Payment method</h2>
                        <h3 className="poppins lh-24 f-16 c-667085">
                            You won’t be charged now, payment will be collected in store
                            after your appointment
                        </h3>
                    </div>
                    {userData?.cards?.length > 0 && (
                        <div className="d-flex justify-content-center">
                            <div className="border rounded d-flex">
                                <button
                                    type="button"
                                    disabled={userData?.cards?.length === 0}
                                    onClick={() => setPaymentMethod('existing')}
                                    className={`border-0 outline-none py-2 w-88 cursor-pointer ${paymentMethod === 'existing'
                                        ? 'bg-gray'
                                        : 'bg-transparent'
                                        }`}
                                >
                                    Select Existing
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setPaymentMethod('new')}
                                    className={`border-0 outline-none p-2 w-88 cursor-pointer ${paymentMethod === 'new'
                                        ? 'bg-gray'
                                        : 'bg-transparent'
                                        }`}
                                >
                                    Add New
                                </button>
                            </div>
                        </div>
                    )}
                    {paymentMethod === 'existing' && userData?.cards?.length > 0 ? (
                        <div>
                            <h2 className="poppins fw-600 f-18 mt-3">
                                Choose a Saved Payment Method
                            </h2>
                            <div className="row mt-4">
                                {Array.isArray(userData?.cards) &&
                                    userData.cards.map((item, index) => (
                                        <div
                                            className="col-sm-6 col-12 p-3"
                                            key={index}
                                        >
                                            <div
                                                className={`border rounded-4 py-2 px-3 d-flex flex-column gap-4 cp ${cardId === item.id
                                                    ? 'select-card-bg'
                                                    : ''
                                                    }`}
                                                onClick={() =>
                                                    handleExistingCard(item.id)
                                                }
                                            >
                                                <div className="d-flex justify-content-between align-items-baseline gap-2">
                                                    {item?.card?.brand === 'visa' ? (
                                                        <VisaCardSvg />
                                                    ) : item?.card?.brand ===
                                                        'mastercard' ? (
                                                        <MasterCardSvg />
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div className="cp d-flex justify-content-between align-items-center gap-2 flex-wrap">
                                                    <p className="mb-0">
                                                        **** **** ****{' '}
                                                        {item?.card?.last4}
                                                    </p>
                                                    <p className="mb-0">
                                                        Expire: {item?.card?.exp_month}/
                                                        {item?.card?.exp_year}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 mt-5 ">
                                    <label className="poppins c-667085 f-14 lh-20">
                                        Booking notes
                                    </label>
                                </div>
                                <div>
                                    <textarea
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                        type="textarea"
                                        rows="6"
                                        placeholder="Include comments or request about your booking"
                                        className="w-100 border border-color-c-e1e6ef poppins rounded-5 pt-3   px-3 lh-24 f-16 c-0f151d"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Elements stripe={stripePromise}>
                            <StripeCard
                                setStripeToken={setStripeToken}
                                getToken={getToken}
                                setGetToken={setGetToken}
                                notes={notes}
                                setNotes={setNotes}
                                setPaymentMethodId={setPaymentMethodId}
                            />
                        </Elements>
                    )}
                </>
            }
        </div>
    );
};

export default PaymentMethod;
