import React, { useState } from 'react';
import {
    DeleteSvg,
    DotsIcon,
    MasterCardSvg,
    PlusIcon,
    VisaCardSvg,
} from './svgs';
import AddPaymentModal from './AddPaymentModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deletePaymentMethod } from '../../redux/users/actionCreator';
import { Spin } from 'antd';

const PaymentMethods = () => {
    const dispatch = useDispatch();

    const { userData, isCardLoading } = useSelector((state) => state.user);
    const [deletingId, setDeletingId] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleDelete = (id) => {
        setDeletingId(id);
        dispatch(
            deletePaymentMethod(id, () => {
                setDeletingId('');
            })
        );
    };

    console.log(userData);
    return (
        <div>
            <div className="d-flex justify-content-lg-between w-100  pt-4">
                <div className="d-flex justify-content-lg-between w-100 ps-3 flex-column flex-lg-row gap-4 gap-lg-0 ">
                    <div>
                        <p className="m-0 poppins f-16 lh-20 c-0f151d fw-medium">
                            My payment methods
                        </p>
                        <p className="m-0 poppins lh-20  c-667085 pt-2">
                            Securely save your card details for hassle - free
                            payments.
                        </p>
                    </div>
                    <div>
                        <button
                            className="d-flex gap-2 border-0 bg-transparent"
                            onClick={toggleModal}
                        >
                            <PlusIcon />
                            <div className=" poppins f-16 lh-20 c-0f151d fw-medium border-0 bg-transparent">
                                Add payment method
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row mt-4 border-bottom pb-4">
                {Array.isArray(userData?.cards) &&
                    userData.cards.map((item, index) => (
                        <div className="col-sm-6 col-12 p-3" key={index}>
                            <div className="border rounded-4 py-2 px-3 d-flex flex-column gap-4">
                                <div className="d-flex justify-content-between align-items-baseline gap-2">
                                    {item?.card?.brand === 'visa' ? (
                                        <VisaCardSvg />
                                    ) : item?.card?.brand === 'mastercard' ? (
                                        <MasterCardSvg />
                                    ) : (
                                        ''
                                    )}
                                    <div
                                        className="d-flex align-items-baseline cp"
                                        onClick={() => {
                                            handleDelete(item.id);
                                        }}
                                    >
                                        {isCardLoading &&
                                        deletingId === item.id ? (
                                            <Spin />
                                        ) : (
                                            <DeleteSvg />
                                        )}
                                    </div>
                                </div>
                                <div className="cp d-flex justify-content-between align-items-center gap-2 flex-wrap">
                                    <p className="mb-0">
                                        **** **** **** {item?.card?.last4}
                                    </p>
                                    <p className="mb-0">
                                        Expire: {item?.card?.exp_month}/
                                        {item?.card?.exp_year}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <AddPaymentModal
                isModalVisible={isModalVisible}
                handleClose={toggleModal}
            />
        </div>
    );
};

export default PaymentMethods;
