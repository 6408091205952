import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

const Terms = () => {
    return (
        <div className={`landing-page-body`}>

            <div className="header-bg">
                <Header/>
            </div>
            <div className={`h-38vh`}>
                <h3 className={`my-5 px-md-120 px-5`}>Terms And Services</h3>
            </div>
            <Footer/>
        </div>
    )
}

export default Terms
