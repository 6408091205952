/* eslint-disable no-shadow */
/* eslint-disable */
import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MenueItems from './MenueItems';
import { Div } from './style';
// import HeaderSearch from '../components/header-search/header-search';
// import AuthInfo from '../components/utilities/auth-info/info';
import '../static/css/style.css';

import logo from '../static/img/logo.png';

import longlogo from '../assets/images/logo.svg';
import { destroySession } from '../helpers/functions';
import {
    calendarIcon,
    createServices,
    customersIcon,
    dashboardIcon,
    moneyBag,
    setting,
    review,
    catalogueIcon,
    messageIcon,
    automatedMessage,
} from '../assets/images/sidebarIcons';

const { darkTheme } = require('../config/theme/themeVariables');

const { Header, Footer, Sider, Content } = Layout;
// const { darkMode } = config;

const ThemeLayout = (WrappedComponent) => {
    class LayoutComponent extends Component {
        constructor(props) {
            super(props);
            this.state = {
                collapsed: false,
                hide: true,
                searchHide: true,
                activeSearch: false,
                openSideBar: false,
            };
            this.updateDimensions = this.updateDimensions.bind(this);
            this.handleRedirect = this.handleRedirect.bind(this);
        }

        componentDidMount() {
            window.addEventListener('resize', this.updateDimensions);
            this.updateDimensions();
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateDimensions);
        }

        updateDimensions() {
            this.setState({
                collapsed: window.innerWidth <= 1150 && true,
            });
        }

        handleRedirect() {
            const { history } = this.props;
            destroySession();
            history.push('/login');
        }

        render() {
            const { collapsed, hide, searchHide, activeSearch, openSideBar } =
                this.state;
            const { ChangeLayoutMode, rtl, topMenu, hasSubscription } =
                this.props;

            const left = !rtl ? 'left' : 'right';
            const darkMode = ChangeLayoutMode;

            const handleSideBarClick = () => {
                this.setState({
                    openSideBar: !openSideBar,
                });
            };

            const toggleCollapsedMobile = () => {
                if (window.innerWidth <= 990) {
                    this.setState({
                        collapsed: !collapsed,
                    });
                }
            };

            const SideBarStyle = {
                margin: '0',
                padding: '41px 0px 55px',
                overflowY: 'auto',
                height: '100vh',
                position: 'fixed',
                [left]: 0,
                zIndex: 998,
                background: '#334155',
            };

            return (
                <Div darkMode={darkMode}>
                    <Layout className="layout">
                        <Header
                            className={`bg-transparent box-shadow-0 d-top-header h-76 align-items-center justify-content-between`}
                        >
                            <div className="col-auto px-0 navbar-brand">
                                <Link to="/home">
                                    <img
                                        src={logo}
                                        className={`img-fluid`}
                                        alt="Logo"
                                    />
                                </Link>
                            </div>

                            <div className="col-auto px-0 navbar-brand">
                                {!topMenu || window.innerWidth <= 991 ? (
                                    <Button
                                        type="link"
                                        onClick={handleSideBarClick}
                                    >
                                        <img
                                            src={require(`../static/img/icon/${
                                                collapsed
                                                    ? 'left.svg'
                                                    : 'right.svg'
                                            }`)}
                                            alt="menu"
                                        />
                                    </Button>
                                ) : null}
                            </div>
                        </Header>

                        {/* <div className="header-more">
                            <Row>
                                <Col md={0} sm={24} xs={24}>
                                    <div className="small-screen-headerRight">
                                        <SmallScreenSearch
                                            hide={searchHide}
                                            darkMode={darkMode}
                                        >
                                            <HeaderSearch rtl={rtl} />
                                        </SmallScreenSearch>
                                        <SmallScreenAuthInfo
                                            hide={hide}
                                            darkMode={darkMode}
                                        >
                                            <AuthInfo rtl={rtl} />
                                        </SmallScreenAuthInfo>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                        <Layout>
                            <ThemeProvider theme={darkTheme}>
                                <Sider
                                    width={220}
                                    style={SideBarStyle}
                                    collapsed={collapsed}
                                    theme={'dark'}
                                    className={`ant-layout-rtl sidebar-scroll`}
                                >
                                    {/* <Scrollbars
                                        className="custom-scrollbar"
                                        autoHide
                                        autoHideTimeout={500}
                                        autoHideDuration={200}
                                        renderThumbHorizontal={
                                            renderThumbHorizontal
                                        }
                                        renderThumbVertical={
                                            renderThumbVertical
                                        }
                                        renderView={renderView}
                                        renderTrackVertical={
                                            renderTrackVertical
                                        }
                                    > */}
                                    <div className={`text-center mb-35`}>
                                        <Link to="/home">
                                            <img
                                                src={logo}
                                                className={`img-fluid`}
                                                alt="Logo"
                                            />
                                        </Link>
                                    </div>
                                    <MenueItems
                                        topMenu={topMenu}
                                        rtl={rtl}
                                        toggleCollapsed={toggleCollapsedMobile}
                                        darkMode={darkMode}
                                    />

                                    {/* </Scrollbars> */}
                                </Sider>
                            </ThemeProvider>

                            <Layout className="atbd-main-layout mt-0">
                                <Content>
                                    <WrappedComponent {...this.props} />
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>

                    <div
                        className={`modal right-sidebar ${
                            openSideBar ? '' : 'd-none'
                        }`}
                    >
                        <div className={`right-sidebar-content`}>
                            <div className={`right-sidebar-header`}>
                                <div className={`container-fluid`}>
                                    <div className={`row align-items-center`}>
                                        <div className={`col px-0`}>
                                            <img src={longlogo} alt="Logo" />
                                        </div>
                                        <div className={`col-auto px-0`}>
                                            <div
                                                className={`cp`}
                                                onClick={handleSideBarClick}
                                            >
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`right-sidebar-body`}>
                                <div className={`container-fluid`}>
                                    <div
                                        className={`row align-items-between min-h-100vh`}
                                    >
                                        <div className={`col-12 h-81vh`}>
                                            <div className={`container-fluid`}>
                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={'/dashboard'}
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={dashboardIcon}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Home
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={
                                                        '/dashboard/app/calendar/month'
                                                    }
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={calendarIcon}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Calendar
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={
                                                        '/dashboard/customer-history'
                                                    }
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={customersIcon}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Customer History
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={'/dashboard/services'}
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={createServices}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Services
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={'/pricing-plan'}
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={catalogueIcon}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Pricing Plan
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={'/dashboard/setting'}
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={setting}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Settings
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={() => {
                                                        this.setState({
                                                            openSideBar:
                                                                !openSideBar,
                                                        });
                                                        window.open(
                                                            'https://dashboard.stripe.com/login',
                                                            '_blank'
                                                        );
                                                    }}
                                                    to="/dashboard"
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={moneyBag}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Payments
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={'/dashboard/chat'}
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={messageIcon}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Chat
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={
                                                        '/dashboard/business-reviews'
                                                    }
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={review}
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Reviews
                                                        </span>
                                                    </div>
                                                </Link>

                                                <Link
                                                    className={`row align-items-center mb-24 text-decoration-none`}
                                                    onClick={handleSideBarClick}
                                                    to={
                                                        '/dashboard/automated-messages'
                                                    }
                                                >
                                                    <div
                                                        className={`col-auto px-0`}
                                                    >
                                                        <img
                                                            src={
                                                                automatedMessage
                                                            }
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-auto lh-0`}
                                                    >
                                                        <span
                                                            className={`f-16 fw-normal text-white poppins lh-normal`}
                                                        >
                                                            Automated Messages
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        {/* <div className={`col-12`}>
                        <div className={`container-fluid`}>
                          <div className={`row align-items-center mb-24 text-decoration-none`} onClick={this.handleRedirect} >
                            <div className={`col-auto px-0`}>
                              <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.6029 3.69966C15.0935 3.62988 14.5725 3.59375 14.0424 3.59375C8.13264 3.59375 3.3418 8.08517 3.3418 13.6256C3.3418 19.1661 8.13264 23.6575 14.0424 23.6575C14.5725 23.6575 15.0935 23.6214 15.6029 23.5516"
                                    stroke="white" strokeWidth="1.67198"/>
                                <path d="M23.4053 13.6252H13.0037M15.6031 10.2812L12.2588 13.6252L15.6031 16.9692"
                                      stroke="white" strokeWidth="1.67198"/>
                              </svg>
                            </div>
                            <div className={`col-auto lh-0`}>
                              <span className={`f-16 fw-normal text-white poppins lh-normal`}>
                                Sign Out
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            ChangeLayoutMode: state.ChangeLayoutMode.data,
            rtl: state.ChangeLayoutMode.rtlData,
            topMenu: state.ChangeLayoutMode.topMenu,
            hasSubscription: state?.user?.userData?.subscription !== null,
        };
    };

    LayoutComponent.propTypes = {
        ChangeLayoutMode: propTypes.bool,
        rtl: propTypes.bool,
        topMenu: propTypes.bool,
        changeRtl: propTypes.func,
        changeLayout: propTypes.func,
        changeMenuMode: propTypes.func,
    };

    return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
