import React from "react";
import footerLogo from "../assets/images/footer-logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <footer>
            <div className={`footer-bg px-3`}>
                <div className={`container`}>
                    <div className={`col-12 pt-40 pb-22`}>
                        <div className={`d-flex align-items-center justify-content-center`}>
                            <div className={`col-md col-6`}>
                                <div className={`border-1-ff66`} />
                            </div>
                            <div className={`col-auto px-md-45`}>
                                <img src={footerLogo} alt="Logo" className={`img-fluid`} />
                            </div>
                            <div className={`col-md col-6`}>
                                <div className={`border-1-ff66`} />
                            </div>
                        </div>
                    </div>
                    <div className={`row pb-md-30 pb-3 justify-content-between`}>
                        {/* <div className={`col-lg-2 col-md-3 col-6 pb-md-0 pb-5`}>
                            <div className={`d-flex flex-column align-items-md-start`}>
                                <div className="d-flex flex-column gap-4">
                                    <div>
                                        <h5 className={`text-white poppins f-20 fw-normal lh-24 mb-24 m-0 d-inline-block`}>
                                            About Tisume
                                        </h5>
                                    </div>
                                    <div className="d-flex flex-column gap-3">
                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Careers
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Customer Support
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Blog
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Sitemap
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`col-lg-2 col-md-3 col-6 pb-md-0 pb-5`}>
                            <div className={`d-flex flex-column align-items-md-start`}>
                                <div className="d-flex flex-column gap-4">
                                    <div>
                                        <h5 className={`text-white poppins f-20 fw-normal lh-24 mb-24 m-0 d-inline-block`}>
                                            For business
                                        </h5>
                                    </div>
                                    <div className="d-flex flex-column gap-3">
                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            For partners
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Pricing
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Support
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className={`col-lg-2 col-md-3 col-6 pb-md-0 pb-5`}>
                            <div className={`d-flex flex-column align-items-md-end`}>
                                <div className="d-flex flex-column gap-4">
                                    <div>
                                        <h5 className={`text-white poppins f-20 fw-normal lh-24 mb-24 m-0 d-inline-block`}>
                                            Legal
                                        </h5>
                                    </div>
                                    <div className="d-flex flex-column gap-3">
                                        <Link to="/privacy-policy"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Privacy Policy
                                        </Link>

                                        <Link to="/business-terms"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Terms of use
                                        </Link>
                                        <div
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>

                                        </div>
                                        <div
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>

                                        </div>

                                        <Link to="/terms"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Terms of use
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className={`col-lg-2 col-md-3 col-6 pb-md-0 pb-5`}>
                            <div className={`d-flex flex-column align-items-md-end`}>
                                <div className="d-flex flex-column gap-4">
                                    <div>
                                        <h5 className={`text-white poppins f-20 fw-normal lh-24 mb-24 m-0 d-inline-block`}>
                                            Find us on social
                                        </h5>
                                    </div>
                                    <div className="d-flex flex-column gap-3">
                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Facebook
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Twitter
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            LinkedIn
                                        </Link>

                                        <Link to="#"
                                            className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                            Instagram
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* <div className={`border-1-fff33`} /> */}

                <div className={`container`}>
                    <div className={`row align-items-center justify-content-center pt-md-10 pb-md-20 pt-2 pb-3`}>

                        <div className={`col-auto px-16`}>
                            <Link to="/privacy-policy"
                                className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                Privacy Policy
                            </Link>
                        </div>

                        <div className={`col-auto px-16`}>
                            <div className={`vl h-8`} />
                        </div>

                        <div className={`col-auto px-16`}>
                            <Link to="/business-terms"
                                className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                Terms of use
                            </Link>
                        </div>

                        {/* <div className={`col-auto px-16`}>
                            <div className={`vl h-8`} />
                        </div>

                        <div className={`col-auto px-0`}>
                            <a href="/terms"
                                className={`c-99a3b8 poppins f-14 fw-normal lh-17 text-decoration-none`}>
                                Terms of use
                            </a>
                        </div> */}

                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer