export const MasterCardSvg = () => (
    <svg
        width="44"
        height="27"
        viewBox="0 0 44 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.4985 26.9969C20.9534 26.9969 26.9969 20.9534 26.9969 13.4985C26.9969 6.04346 20.9534 0 13.4985 0C6.04346 0 0 6.04346 0 13.4985C0 20.9534 6.04346 26.9969 13.4985 26.9969Z"
            fill="#FF6041"
        />
        <path
            d="M30.5009 26.9969C37.9559 26.9969 43.9994 20.9534 43.9994 13.4985C43.9994 6.04346 37.9559 0 30.5009 0C23.0459 0 17.0024 6.04346 17.0024 13.4985C17.0024 20.9534 23.0459 26.9969 30.5009 26.9969Z"
            fill="#FFD941"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9996 3.11568C23.5578 4.37492 24.8147 5.96676 25.6781 7.77461C26.5415 9.58247 26.9896 11.5605 26.9896 13.564C26.9896 15.5675 26.5415 17.5455 25.6781 19.3534C24.8147 21.1612 23.5578 22.7531 21.9996 24.0123C20.4297 22.7536 19.1626 21.1583 18.2919 19.3443C17.4213 17.5302 16.9692 15.5437 16.9692 13.5316C16.9692 11.5194 17.4213 9.53289 18.2919 7.71883C19.1626 5.90477 20.4297 4.37438 21.9996 3.11568Z"
            fill="#FFD941"
        />
        <mask
            id="mask0_100_4846"
            maskUnits="userSpaceOnUse"
            x="16"
            y="3"
            width="11"
            height="22"
        >
            <path
                d="M22.0011 3.11665C23.5593 4.3759 24.8161 5.96774 25.6796 7.77559C26.543 9.58344 26.9911 11.5615 26.9911 13.565C26.9911 15.5684 26.543 17.5465 25.6796 19.3544C24.8161 21.1622 23.5593 22.7541 22.0011 24.0133C20.4312 22.7546 19.1641 21.1593 18.2934 19.3453C17.4227 17.5312 16.9707 15.5447 16.9707 13.5325C16.9707 11.5203 17.4227 9.53387 18.2934 7.71981C19.1641 5.90575 20.4312 4.37535 22.0011 3.11665Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_100_4846)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5366 5.71094H30.6309V7.46314H14.5366V5.71094ZM14.5366 9.15045H30.6309V10.9026H14.5366V9.15045ZM14.5366 12.6549H30.6309V14.4071H14.5366V12.6549ZM14.5366 16.0944H30.6309V17.8466H14.5366V16.0944ZM14.5366 19.5339H30.6309V21.2861H14.5366V19.5339Z"
                fill="#FF6041"
            />
        </g>
    </svg>
);

export const VisaCardSvg = () => (
    <svg
        width="30"
        height="27"
        viewBox="0 0 30 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_100_4860)">
            <path
                d="M11 20.3047L12.5238 9.75781H14.9048L13.4286 20.3047H11Z"
                fill="#3C58BF"
            />
            <path
                d="M11 20.3047L12.9524 9.75781H14.9048L13.4286 20.3047H11Z"
                fill="#293688"
            />
            <path
                d="M22.0474 9.875C21.5712 9.64062 20.8093 9.40625 19.857 9.40625C17.476 9.40625 15.7617 10.8711 15.7617 12.9805C15.7617 14.5625 16.9522 15.3828 17.9046 15.9101C18.857 16.4375 19.1427 16.789 19.1427 17.2578C19.1427 17.9609 18.3808 18.3125 17.7141 18.3125C16.7617 18.3125 16.2379 18.1367 15.4284 17.7265L15.0951 17.5508L14.7617 19.9531C15.3331 20.2461 16.3808 20.539 17.476 20.539C19.9998 20.539 21.6665 19.0742 21.6665 16.8476C21.6665 15.6172 21.0474 14.6797 19.6189 13.918C18.7617 13.3906 18.2379 13.0976 18.2379 12.5703C18.2379 12.1016 18.6665 11.6328 19.6189 11.6328C20.4284 11.6328 20.9998 11.8086 21.4284 12.043L21.6665 12.1601L22.0474 9.875Z"
                fill="#3C58BF"
            />
            <path
                d="M22.0474 9.875C21.5712 9.64062 20.8093 9.40625 19.857 9.40625C17.476 9.40625 16.1903 10.8711 16.1903 12.9805C16.1903 14.5625 16.9522 15.3828 17.9046 15.9101C18.857 16.4375 19.1427 16.789 19.1427 17.2578C19.1427 17.9609 18.3808 18.3125 17.7141 18.3125C16.7617 18.3125 16.2379 18.1367 15.4284 17.7265L15.0951 17.5508L14.7617 19.9531C15.3331 20.2461 16.3808 20.539 17.476 20.539C19.9998 20.539 21.6665 19.0742 21.6665 16.8476C21.6665 15.6172 21.0474 14.6797 19.6189 13.918C18.7617 13.3906 18.2379 13.0976 18.2379 12.5703C18.2379 12.1016 18.6665 11.6328 19.6189 11.6328C20.4284 11.6328 20.9998 11.8086 21.4284 12.043L21.6665 12.1601L22.0474 9.875Z"
                fill="#293688"
            />
            <path
                d="M26.1435 9.75781C25.5721 9.75781 25.1435 9.81641 24.9054 10.5195L21.334 20.3047H23.9054L24.3816 18.5468H27.4292L27.7149 20.3047H30.0007L28.0007 9.75781H26.1435ZM25.0483 16.789C25.1911 16.2617 26.0007 13.6836 26.0007 13.6836C26.0007 13.6836 26.1911 13.0391 26.334 12.6289L26.4768 13.625C26.4768 13.625 26.953 16.2617 27.0483 16.8476H25.0483V16.789Z"
                fill="#3C58BF"
            />
            <path
                d="M26.7149 9.75781C26.1435 9.75781 25.7149 9.81641 25.4768 10.5195L21.334 20.3047H23.9054L24.3816 18.5468H27.4292L27.7149 20.3047H30.0007L28.0007 9.75781H26.7149ZM25.0483 16.789C25.2387 16.2031 26.0007 13.6836 26.0007 13.6836C26.0007 13.6836 26.1911 13.0391 26.334 12.6289L26.4768 13.625C26.4768 13.625 26.953 16.2617 27.0483 16.8476H25.0483V16.789Z"
                fill="#293688"
            />
            <path
                d="M6.6183 17.1377L6.38021 15.6142C5.95164 13.8564 4.57068 11.9228 3.04688 10.9853L5.18973 20.3603H7.76116L11.6183 9.81348H9.04688L6.6183 17.1377Z"
                fill="#3C58BF"
            />
            <path
                d="M6.6183 17.1377L6.38021 15.6142C5.95164 13.8564 4.57068 11.9228 3.04688 10.9853L5.18973 20.3603H7.76116L11.6183 9.81348H9.52307L6.6183 17.1377Z"
                fill="#293688"
            />
            <path
                d="M0 9.75781L0.428571 9.875C3.47619 10.7539 5.57143 12.9805 6.38095 15.6172L5.52381 10.6367C5.38095 9.93359 4.95238 9.75781 4.42857 9.75781H0Z"
                fill="#FFBC00"
            />
            <path
                d="M0 9.75781C3.04762 10.6367 5.57143 12.9219 6.38095 15.5586L5.57143 11.3984C5.42857 10.6953 4.95238 10.2852 4.42857 10.2852L0 9.75781Z"
                fill="#F7981D"
            />
            <path
                d="M0 9.75781C3.04762 10.6367 5.57143 12.9219 6.38095 15.5586L5.80952 13.2734C5.66667 12.5703 5.47619 11.8672 4.80952 11.5742L0 9.75781Z"
                fill="#ED7C00"
            />
            <path
                d="M9.00046 16.7881L7.38142 14.7959L6.61951 17.0224L6.42904 15.5576C6.00047 13.7998 4.61951 11.8662 3.0957 10.9287L5.23856 20.3037H7.80999L9.00046 16.7881ZM13.429 20.3037L11.3814 17.7256L11.0005 20.3037H13.429ZM18.9052 16.6709C19.0957 16.9053 19.1909 17.081 19.1433 17.3154C19.1433 18.0185 18.3814 18.3701 17.7148 18.3701C16.7624 18.3701 16.2386 18.1943 15.429 17.7842L15.0957 17.6084L14.7624 20.0107C15.3338 20.3037 16.3814 20.5967 17.4767 20.5967C19.0005 20.5967 20.2386 20.0693 20.9528 19.1318L18.9052 16.6709ZM21.6671 20.3037H23.9052L24.3814 18.5459H27.429L27.7148 20.3037H30.0005L29.1909 16.0264L26.3338 12.6279L26.4767 13.5654C26.4767 13.5654 26.9528 16.2021 27.0481 16.7881H25.0481C25.2386 16.2021 26.0005 13.6826 26.0005 13.6826C26.0005 13.6826 26.1909 13.0381 26.3338 12.6279"
                fill="#051244"
            />
        </g>
        <defs>
            <clipPath id="clip0_100_4860">
                <rect width="30" height="27" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const DotsIcon = () => (
    <svg
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.125 8C3.125 8.2225 3.05902 8.44001 2.9354 8.62502C2.81179 8.81002 2.63609 8.95422 2.43052 9.03936C2.22495 9.12451 1.99875 9.14679 1.78052 9.10338C1.56229 9.05998 1.36184 8.95283 1.20451 8.7955C1.04717 8.63816 0.940025 8.43771 0.896617 8.21948C0.853209 8.00125 0.875487 7.77505 0.960636 7.56948C1.04578 7.36391 1.18998 7.18821 1.37498 7.0646C1.55999 6.94098 1.7775 6.875 2 6.875C2.29837 6.875 2.58452 6.99353 2.79549 7.20451C3.00647 7.41548 3.125 7.70163 3.125 8ZM2 2.75C2.2225 2.75 2.44001 2.68402 2.62502 2.5604C2.81002 2.43679 2.95422 2.26109 3.03936 2.05552C3.12451 1.84995 3.14679 1.62375 3.10338 1.40552C3.05998 1.1873 2.95283 0.986839 2.79549 0.829505C2.63816 0.672171 2.43771 0.565025 2.21948 0.521617C2.00125 0.478209 1.77505 0.500487 1.56948 0.585636C1.36391 0.670785 1.18821 0.814979 1.0646 0.999984C0.94098 1.18499 0.875001 1.4025 0.875001 1.625C0.875001 1.92337 0.993527 2.20952 1.20451 2.4205C1.41548 2.63147 1.70163 2.75 2 2.75ZM2 13.25C1.7775 13.25 1.55999 13.316 1.37498 13.4396C1.18998 13.5632 1.04578 13.7389 0.960636 13.9445C0.875487 14.15 0.853209 14.3762 0.896617 14.5945C0.940025 14.8127 1.04717 15.0132 1.20451 15.1705C1.36184 15.3278 1.56229 15.435 1.78052 15.4784C1.99875 15.5218 2.22495 15.4995 2.43052 15.4144C2.63609 15.3292 2.81179 15.185 2.9354 15C3.05902 14.815 3.125 14.5975 3.125 14.375C3.125 14.0766 3.00647 13.7905 2.79549 13.5795C2.58452 13.3685 2.29837 13.25 2 13.25Z"
            fill="black"
        />
    </svg>
);

export const PlusIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H10.625V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H9.375V3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125V9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10Z"
            fill="#334155"
        />
    </svg>
);

export const DeleteSvg = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.875 3.75H13.75V3.125C13.75 2.62772 13.5525 2.15081 13.2008 1.79917C12.8492 1.44754 12.3723 1.25 11.875 1.25H8.125C7.62772 1.25 7.15081 1.44754 6.79917 1.79917C6.44754 2.15081 6.25 2.62772 6.25 3.125V3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM7.5 3.125C7.5 2.95924 7.56585 2.80027 7.68306 2.68306C7.80027 2.56585 7.95924 2.5 8.125 2.5H11.875C12.0408 2.5 12.1997 2.56585 12.3169 2.68306C12.4342 2.80027 12.5 2.95924 12.5 3.125V3.75H7.5V3.125ZM15 16.25H5V5H15V16.25ZM8.75 8.125V13.125C8.75 13.2908 8.68415 13.4497 8.56694 13.5669C8.44973 13.6842 8.29076 13.75 8.125 13.75C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125V8.125C7.5 7.95924 7.56585 7.80027 7.68306 7.68306C7.80027 7.56585 7.95924 7.5 8.125 7.5C8.29076 7.5 8.44973 7.56585 8.56694 7.68306C8.68415 7.80027 8.75 7.95924 8.75 8.125ZM12.5 8.125V13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75C11.7092 13.75 11.5503 13.6842 11.4331 13.5669C11.3158 13.4497 11.25 13.2908 11.25 13.125V8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5C12.0408 7.5 12.1997 7.56585 12.3169 7.68306C12.4342 7.80027 12.5 7.95924 12.5 8.125Z"
            fill="#EB5757"
        />
    </svg>
);
