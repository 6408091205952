import {
  GET_CHAT_BY_USER_ID,
  GET_MESSAGES_BY_CHAT_ID,
  SET_CHAT_LOADING,
  SET_GET_MESSAGES_LOADING,
  SET_INITIALIZING_CHAT_LOADING,
  SET_SEND_MESSAGE_LOADER,
} from "./types";

const initialState = {
  allChats: [],
  chatMessages: [],
  isInitializingChat: false,
  isGettingChats: false,
  isGettingMessages: false,
  messages: [],
  isSendingMessage: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Chats

    case GET_CHAT_BY_USER_ID:
      return {
        ...state,
        allChats: action.payload,
      };

    case SET_INITIALIZING_CHAT_LOADING:
      return {
        ...state,
        isInitializingChat: action.payload,
      };
    case SET_CHAT_LOADING:
      return {
        ...state,
        isGettingChats: action.payload,
      };
    case GET_MESSAGES_BY_CHAT_ID:
      return {
        ...state,
        messages: action.payload,
      };
    case SET_GET_MESSAGES_LOADING:
      return {
        ...state,
        isGettingMessages: action.payload,
      };
    case SET_SEND_MESSAGE_LOADER:
      return {
        ...state,
        isSendingMessage: action.payload,
      };

    default:
      return state;
  }
};

export default chatReducer;
