import React, { useEffect, useState } from "react";
import Stepper from "./stepper";
import Form from "./form";
import "./step.css";


const BusinessForm = () => {

  // const { token } = useParams();

  // const hasEffectRun = useRef(false);


  const [formData, setFormData] = useState({
    step: 0,
    name: "",
    website: "",
    services: [],
    location: "",
    images: [],
  });

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (window.location.pathname.includes("upload-from-instagram")) {
      const storedFormData = JSON.parse(localStorage.getItem("formData"));
      // validateToken(storedFormData.token);
      if (storedFormData) {
        setFormData(storedFormData);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (!hasEffectRun.current) {
  //     validateToken(token);

  //     hasEffectRun.current = true;
  //   }
  // }, [token]);

  // const validateToken = async (token) => {
  //   try {
  //     if (token) {
  //       console.log(token, "Token");
  //       const apiUrl = `user/verify-email/${token}`;
  //       const response = await Axios.get(apiUrl);

  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         user_id: response.data.id,
  //         token: token,
  //       }));

  //       dispatch(setUser(response.data));

  //       setMessage("Email verified Successfully");

  //       console.log(response.data.id);
  //     }
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //     history.push("/not-found");
  //   }
  // };

  // const steps = ['Business Name', 'Services you offer', 'Set Location', 'Pictures'];
  const steps = [
    "Business Name",
    "Services you offer",
    "Set Location",
    "Upload Photos",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = () => {
    setFormData((prevData) => ({
      ...prevData,
      step: prevData.step + 1,
    }));
  };

  const handlePrev = () => {
    setFormData((prevData) => ({
      ...prevData,
      step: prevData.step - 1,
    }));
  };

  return (
    <div>
      <Stepper steps={steps} currentStep={formData.step} />
      <Form
        setFormData={setFormData}
        formData={formData}
        handleChange={handleChange}
        handleNext={handleNext}
        handlePrev={handlePrev}
        message={message}
      />
    </div>
  );
};

export default BusinessForm;
