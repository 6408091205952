import React, { useEffect, useRef, useState } from 'react';
import { TimeSvg } from '../../assets/SearchbarSVGs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    updateExtraFieldValues,
    updateFilteredValues,
} from '../../redux/services/servicesActions';
import { Dropdown, Menu, Radio } from 'antd';

function TimeSelector({ filteredError }) {
    const dispatch = useDispatch();
    const timeRef = useRef();
    const { filteredValues, extraFields } = useSelector(
        (state) => state.services
    );

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleOptionChange = (e) => {
        e.preventDefault()
        const value = e.target.value;

        switch (value) {
            case 'anyTime':
                dispatch(
                    updateFilteredValues({
                        ...filteredValues,
                        fromTime: '00:00',
                        toTime: '23:59',
                    })
                );
                dispatch(
                    updateExtraFieldValues({
                        ...extraFields,
                        timeOption: value,
                    })
                );
                // setIsDisabled(true);
                break;
            case 'morning':
                dispatch(
                    updateFilteredValues({
                        ...filteredValues,
                        fromTime: '08:00',
                        toTime: '12:00',
                    })
                );
                dispatch(
                    updateExtraFieldValues({
                        ...extraFields,
                        timeOption: value,
                    })
                );
                // setIsDisabled(true);
                break;
            case 'afternoon':
                dispatch(
                    updateFilteredValues({
                        ...filteredValues,
                        fromTime: '12:00',
                        toTime: '17:00',
                    })
                );
                dispatch(
                    updateExtraFieldValues({
                        ...extraFields,
                        timeOption: value,
                    })
                );
                // setIsDisabled(true);
                break;
            case 'evening':
                dispatch(
                    updateFilteredValues({
                        ...filteredValues,
                        fromTime: '17:00',
                        toTime: '21:00',
                    })
                );
                dispatch(
                    updateExtraFieldValues({
                        ...extraFields,
                        timeOption: value,
                    })
                );
                // setIsDisabled(true);
                break;
            default:
                dispatch(
                    updateFilteredValues({
                        ...filteredValues,
                        fromTime: '',
                        toTime: '',
                    })
                );
                dispatch(
                    updateExtraFieldValues({
                        ...extraFields,
                        timeOption: 'anyTime',
                    })
                );
                break;
        }
    };

    const handleFromTimeChange = (e) => {
        e.preventDefault()
        const newFromTime = e.target.value;
        dispatch(
            updateFilteredValues({ ...filteredValues, fromTime: newFromTime })
        );
        dispatch(
            updateExtraFieldValues({ ...extraFields, timeOption: 'manual' })
        );
    };

    const handleToTimeChange = (e) => {
        const newToTime = e.target.value;
        dispatch(
            updateFilteredValues({ ...filteredValues, toTime: newToTime })
        );
        dispatch(
            updateExtraFieldValues({ ...extraFields, timeOption: 'manual' })
        );
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (timeRef.current && !timeRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <div
                    className="d-flex justify-content-between gap-2 mb-24 flex-wrap"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdownVisible(true)
                    }}
                >
                    <div>
                        <input
                            type="radio"
                            id="radio1"
                            name="selector"
                            className="selector-item_radio"
                            value="anyTime"
                            checked={extraFields?.timeOption === 'anyTime'}
                            onChange={(e) => {
                                e.preventDefault();
                                handleOptionChange(e);
                            }}
                        />
                        <label htmlFor="radio1" className="selector-item_label">
                            Any time
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="radio2"
                            name="selector"
                            className="selector-item_radio"
                            value="morning"
                            checked={extraFields?.timeOption === 'morning'}
                            onChange={(e) => {
                                e.preventDefault();
                                handleOptionChange(e);
                            }}
                        />
                        <label htmlFor="radio2" className="selector-item_label">
                            Morning
                        </label>
                    </div>
                    <div className="selector-item ">
                        <input
                            type="radio"
                            id="radio3"
                            name="selector"
                            className="selector-item_radio"
                            value="afternoon"
                            checked={extraFields?.timeOption === 'afternoon'}
                            onChange={(e) => {
                                e.preventDefault();
                                handleOptionChange(e);
                            }}
                        />
                        <label htmlFor="radio3" className="selector-item_label">
                            Afternoon
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="radio4"
                            name="selector"
                            className="selector-item_radio"
                            value="evening"
                            checked={extraFields?.timeOption === 'evening'}
                            onChange={(e) => {
                                e.preventDefault();
                                handleOptionChange(e);
                            }}
                        />
                        <label htmlFor="radio4" className="selector-item_label">
                            Evening
                        </label>
                    </div>
                </div>
                <div className="container-fluid"   onClick={(e) => {
                        e.stopPropagation();
                        setDropdownVisible(true)
                    }}>
                    <div className="d-flex justify-content-between gap-2 flex-wrap">
                        <div className="d-flex flex-column gap-1 flex-grow-1">
                            <div className="">
                                <h6 className="m-0 from">From</h6>
                            </div>
                            <div>
                                <input
                                    type="time"
                                    className="form-control input-field"
                                    value={filteredValues?.fromTime}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        handleFromTimeChange(e);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-1 flex-grow-1">
                            <div>
                                <h6 className="m-0 from">To</h6>
                            </div>
                            <div>
                                <input
                                    type="time"
                                    className="form-control input-field"
                                    value={filteredValues?.toTime}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        handleToTimeChange(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div
            className="p-md-20 px-4 py-8 mb-lg-0 mb-2 bg-white rounded-pill mx-sm-2 mx-0 me-2  custom-border"
            ref={timeRef}
        >
            <Dropdown
                placement="bottomLeft"
                overlay={menu}
                trigger={['click']}
                visible={dropdownVisible}
            >
                <button
                    className="dropdown-toggle border-0 py-md-20 py-8 mb-lg-0 bg-white rounded-pill"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(true);
                    }}
                >
                    <div className="container-fluid px-0">
                        <div className="d-flex align-items-center justify-content-center p-0 gap-2">
                            <div className="ps-0 pe-md-2 pe-0 wh-sm-18">
                                <TimeSvg />
                            </div>
                            <div className="ps-0 position-relative">
                                <h6 className="m-0 f-16 fw-normal lh-24 poppins c-0f151d text-capitalize">
                                    {extraFields?.timeOption === 'manual'
                                        ? `${filteredValues.fromTime} To ${filteredValues.toTime}`
                                        : extraFields?.timeOption}
                                </h6>
                                {(filteredError.fromTime ||
                                    filteredError.toTime) && (
                                    <p className="position-absolute text-danger">
                                        Required
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </button>
            </Dropdown>
        </div>
    );
}

export default TimeSelector;
