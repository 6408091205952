export const CloseIcon = () => (
    <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
        <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#E1E6EF" />
        <path
            d="M26.567 25.1832C26.6251 25.2412 26.6712 25.3102 26.7026 25.386C26.734 25.4619 26.7502 25.5432 26.7502 25.6253C26.7502 25.7075 26.734 25.7888 26.7026 25.8647C26.6712 25.9405 26.6251 26.0095 26.567 26.0675C26.509 26.1256 26.44 26.1717 26.3642 26.2031C26.2883 26.2345 26.207 26.2507 26.1249 26.2507C26.0427 26.2507 25.9614 26.2345 25.8856 26.2031C25.8097 26.1717 25.7407 26.1256 25.6827 26.0675L20.4999 20.8839L15.317 26.0675C15.1998 26.1848 15.0407 26.2507 14.8749 26.2507C14.709 26.2507 14.5499 26.1848 14.4327 26.0675C14.3154 25.9503 14.2495 25.7912 14.2495 25.6253C14.2495 25.4595 14.3154 25.3004 14.4327 25.1832L19.6163 20.0003L14.4327 14.8175C14.3154 14.7003 14.2495 14.5412 14.2495 14.3753C14.2495 14.2095 14.3154 14.0504 14.4327 13.9332C14.5499 13.8159 14.709 13.75 14.8749 13.75C15.0407 13.75 15.1998 13.8159 15.317 13.9332L20.4999 19.1168L25.6827 13.9332C25.7999 13.8159 25.959 13.75 26.1249 13.75C26.2907 13.75 26.4498 13.8159 26.567 13.9332C26.6843 14.0504 26.7502 14.2095 26.7502 14.3753C26.7502 14.5412 26.6843 14.7003 26.567 14.8175L21.3835 20.0003L26.567 25.1832Z"
            fill="black"
        />
    </svg>
);
export const PlusIcon = () => (
    <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.25 9C16.25 9.14918 16.1907 9.29226 16.0852 9.39775C15.9798 9.50324 15.8367 9.5625 15.6875 9.5625H10.0625V15.1875C10.0625 15.3367 10.0032 15.4798 9.89775 15.5852C9.79226 15.6907 9.64918 15.75 9.5 15.75C9.35082 15.75 9.20774 15.6907 9.10225 15.5852C8.99676 15.4798 8.9375 15.3367 8.9375 15.1875V9.5625H3.3125C3.16332 9.5625 3.02024 9.50324 2.91475 9.39775C2.80926 9.29226 2.75 9.14918 2.75 9C2.75 8.85082 2.80926 8.70774 2.91475 8.60225C3.02024 8.49676 3.16332 8.4375 3.3125 8.4375H8.9375V2.8125C8.9375 2.66332 8.99676 2.52024 9.10225 2.41475C9.20774 2.30926 9.35082 2.25 9.5 2.25C9.64918 2.25 9.79226 2.30926 9.89775 2.41475C10.0032 2.52024 10.0625 2.66332 10.0625 2.8125V8.4375H15.6875C15.8367 8.4375 15.9798 8.49676 16.0852 8.60225C16.1907 8.70774 16.25 8.85082 16.25 9Z"
            fill="black"
        />
    </svg>
);
