import { GET_NOTIFICATIONS, READ_NOTIFICATIONS, NOTIFICATIONS_PENDING, NOTIFICATIONS_REJECTED } from "./types";

const initialState = {
  notifications: [],
  isLoading: false,
  readLoading: false,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        isLoading: false,
        notifications: action.payload
      };
    case NOTIFICATIONS_REJECTED:
      return {
        ...state,
        isLoading: false,
      };
    case READ_NOTIFICATIONS:
      return {
        ...state,
        readLoading: action.payload,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
