import { Route, Redirect, useLocation } from "react-router-dom";
import { isAuthenticated } from "../helpers/functions";
import { useSelector } from "react-redux";

export function AuthRoute({ children, ...rest }) {
  const location = useLocation();
  const { userData } = useSelector((state) => state.user);
  let url =
    userData?.role === "customer"
      ? location?.state && location?.state?.fallback
        ? location?.state?.fallback
        : "/customer/upcoming-appointments"
      : "/dashboard";
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() ? (
          <Redirect
            to={{
              pathname: url,
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}
