import { GoogleMap, Marker } from "@react-google-maps/api";

const Mapcomponent = ({ list }) => {
  const mapStyles = {
    width: "100%",
    height: "40rem",
    borderRadius: "20px",
  };

  const mapCenter =
    list?.length > 0
      ? { lat: list[0]?.lat, lng: list[0]?.lng }
      : { lat: 0, lng: 0 };
  const customMapOptions = {
    // gestureHandling: "none", // Disables pan and zoom gestures
    zoomControl: true, // Hide the default zoom control
    mapTypeControl: false,
    mapTypeId: "roadmap",
  };

  return (
    <div className="p-0">
      {list?.length > 0 ? (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={12}
          center={mapCenter}
          options={customMapOptions}
        >
          {list.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.lat, lng: location.lng }}
            />
          ))}
        </GoogleMap>
      ) : (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={2}
          center={{ lat: 0, lng: 0 }}
          options={customMapOptions}
        />
      )}
    </div>
  );
};

export default Mapcomponent;
